<template>
   <b-row class="m-0 p-0 pt-3">
    <b-col cols="12" md="6" class="order-md-1 order-sm-2 order-1 m-0 p-0 d-flex flex-column align-items-center justify-content-center">
        <div class="product-name text-left flex-item mb-3" v-if="product.choosen">
            {{ 
                $i18n.locale()=='kz' ? product.choosen.productName
                : $i18n.locale()=='ru' ? product.choosen.productNameRu 
                : $i18n.locale()=='en' ? product.choosen.productNameEn : ""             
            }}
        </div>
        <div class="product-view-sep flex-item"></div>
        <div class="sub-title flex-item mt-2">
            {{ $t("oyu.landing.fileType") }}
        </div>
        <div class="sub-title flex-item my-2">
            SVG,PNG,JPG
        </div>
        <div class="product-view-sep flex-item"></div>

        <div class="product-download text-center py-2 mt-5 mb-2" @click="downloadProduct()">
            <span>
                {{ $t("oyu.landing.download") }} (SVG)
            </span>
        </div>
        <div class="product-download text-center py-2 mt-1 mb-2" @click="downloadProductPng()">
            <span>
                {{ $t("oyu.landing.download") }} (PNG)
            </span>
        </div>
        <div class="product-download text-center py-2 mt-1 mb-2" @click="downloadProductJpg()">
            <span>
                {{ $t("oyu.landing.download") }} (JPG)
            </span>
        </div>
        <div class="flex-item back-ornaments text-center mt-5" @click="back">
            {{ $t("oyu.landing.backOrnaments") }}
        </div>
    </b-col>
    <b-col cols="12" md="6" class="order-md-2 order-sm-1 order-2 m-0 p-0 view-side text-center 
         d-flex align-items-center justify-content-center" v-if="product.choosen">
        <img style="width: 150px" 
        :src="'https://www.oyuapp.com/api/img?imagePath='+product.choosen.image">
    </b-col>
    <SubModal></SubModal>
  </b-row>
</template>
<script>
 import axios from 'axios';
 import {apiDomain,getHeader} from "@/config/config"; 
 import vue from 'vue';
 import {EventBus} from "@/EventBus.js"
 import OyuService from "@/services/oyu_service.js";
 import { mapState } from 'vuex';
 import SubModal from "@/components/lmslanding/SubModal"
 import { isMobile } from 'mobile-device-detect';
 export default {
    name:"ProductView",
    components:{
        SubModal         
    },
    data() {
        
        return {
            
            product:{},
            productId:0
        }
    },
    methods: { 
        downloadProductPng(){
            
            let obj = this.product.choosen;
            if(this.loginedUser){
                if(Number(this.loginedUser.id)>0){
                    if(Number(obj.isActive)==1){
                        const service = new OyuService(apiDomain,getHeader());
                        service.downloadProductPng({"image":obj.image});
                    }else{
                        this.$bvModal.show('subModal');
                    }
                }else{
                    alert(vue.i18n.translate("oyu.landing.operation"));
                }
            }else{
                alert(vue.i18n.translate("oyu.landing.operation"));
            }
        }, 
        downloadProductJpg(){
            
            let obj = this.product.choosen;
            if(this.loginedUser){
                if(Number(this.loginedUser.id)>0){
                    if(Number(obj.isActive)==1){
                        const service = new OyuService(apiDomain,getHeader());
                        service.downloadProductJpg({"image":obj.image});
                    }else{
                        this.$bvModal.show('subModal');
                    }
                }else{
                    alert(vue.i18n.translate("oyu.landing.operation"));
                }
            }else{
                alert(vue.i18n.translate("oyu.landing.operation"));
            }
        }, 
        downloadProduct(){
            
            let obj = this.product.choosen;
            if(this.loginedUser){
                if(Number(this.loginedUser.id)>0){
                    if(Number(obj.isActive)==1){
                        const service = new OyuService(apiDomain,getHeader());
                        service.downloadProduct({"image":obj.image});
                    }else{
                        this.$bvModal.show('subModal');
                    }
                }else{
                    alert(vue.i18n.translate("oyu.landing.operation"));
                }
            }else{
                alert(vue.i18n.translate("oyu.landing.operation"));
            }
        }, 
        back(){
            window.history.go(-1);
        },
        currentProduct(productId=0){
            
            axios.post(apiDomain+'/oyu/mobile/view',
            {
               "productId":Number(productId)
            },{headers:getHeader()})
            .then(response=>{ 
               this.product=response.data;
            })
            .catch((error) => {
                alert(error.message);
            }) 
        }
    },
    created(){
        this.productId = this.$route.params.productId;
        this.currentProduct(this.productId);   
        
        
        
    },
    watch: {
        $route(to, from) {
            this.productId = this.$route.params.productId;
            this.currentProduct(this.productId);   
           
        }
    },
    computed:{
        ...mapState([
            'loginedUser'
        ]),
        
    }
//   mounted(){
//      let t = this;
//      EventBus.$on("productsByCat",(d)=>{
//         t.homeProducts(Number(d));
//      })
//    }
};

</script>
<style scoped>
    .back-ornaments{
        cursor: pointer;
        min-width: 318px;

        color: var(--black, #000);

        /* Text lg/Regular */
        font-family: Helvetica Neue LT W1G;
        font-size: 18px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .product-download{
        min-width: 318px;


        cursor: pointer;
        border: 1px solid var(--gray-200, #EAECF0);
        background: var(--gray-200, #EAECF0);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .product-download span{
        color: var(--gray-500, #667085);
        /* Text lg/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .product-view-sep{
        min-width: 318px;
        height: 1px;;
        background: #D0D5DD;
        
    }
    .view-side{
        background: var(--gray-50, #F9FAFB);
        min-height: 300px;
    }
    .product-name{
        min-width: 318px;
        color: var(--black, #000);
        /* Text lg/Semibold */
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
       
    }
    .sub-title{
        color: var(--black, #000);
        min-width: 318px;
        /* Text sm/Semibold */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }

</style>
 


