<template>
    <b-row class="pt-5 pb-4 text-light">
        <b-col lg="12" v-for="(c,ci) in courses" :key="ci">
            <b-row class="w-100  border-top-dark  mx-1" style="border-top:1px solid #343a40">
                <b-col lg="12" class="pt-4 mt-5">
                    <h3 class="text-center text-uppercase font-weight-bolder" style="font-family:sofia-pro,sans-serif !important;">{{c.catName}}</h3>        
                    <p class="text-center pt-2 pb-5"><span class="text-muted text-uppercase">{{c.description}}</span></p>        
                </b-col>    
            </b-row>
            <b-row class="justify-content-center ">
                
                <b-col lg="4" class="mr-5 mb-5" v-for="(k,ki) in c.courses" :key="ki">
                    
                    <b-card
                        title-tag="h5"
                        :title="k.courseName"
                         :img-src="''+fileServer()+'/server/image?imagePath='+k.imagePath"
                        img-alt="Image"
                        img-top
                        img-width="80%"
                        img-height=350
                        tag="article"
                        bg-variant="dark"
                        class="mb-2 text-center text-uppercase "
                        style="cursor:pointer !important;white-space: nowrap !important;overflow:hidden;"
                        @click="startCourse(k.courseId)"
                    >
                        <b-card-text style="font-size: 0.7rem !important;cursor:pointer !important;">
                            {{k.description}}
                        </b-card-text>

                        <!-- <b-button block href="#"
                            @click="startCourse(k.courseId)" 
                            variant="success" class="text-uppercase">
                            {{$t("lMenu.start")}}
                        </b-button> -->
                    </b-card>    
                </b-col>
               
            </b-row>    
        </b-col>
         <b-col lg="12">
            <b-row class="w-100  border-top-dark my-5 pt-5 mx-2" style="border-top:1px solid #343a40">
                <b-col lg="4" class="pt-5 mx-auto text-center">
                    Copyright © 2022 CYBERLABS <br>    
                </b-col>    
            </b-row>
         </b-col>    
    </b-row>
</template>
<script>

import axios from 'axios';
import {apiDomain,getHeader} from "@/config/config";
export default {
   name:"AssignedCourse",
   data(){
       return {
           courses:[]
       }
   },
   methods: {
       fileServer(){
            return apiDomain;
        },
       startCourse(courseId){
           window.location.href = "/#/course/"+courseId;
       }
   },

   created(){
        axios.post(apiDomain+'/landing/assigned',{},{headers:getHeader()})
        .then(response=>{
            this.courses = response.data;
        })
        .catch(error => {
            console.log(error)
        })        
   }
}
</script>
