<template>
    <div class="w-100">loading . . . </div>   
</template>
<script>
    import axios from 'axios';
    import {mapActions} from 'vuex';
    import {apiDomain,getHeader} from "../config/config";
    export default {
        name:"Auth",
        methods:{
            ...mapActions([
                'setLoginedUser'
            ]),
            getLoginedUser(result){
                //alert(JSON.stringify(result));
                window.localStorage.setItem("loginedUser",JSON.stringify(result));
                //alert("mina hota "+ window.localStorage.getItem("loginedUser"));
                this.setLoginedUser();
                result.userRoles.forEach(r => {
                    
                    if(r.name=='ADMIN'){
                        //this.$router.push({name:'Deliveries'});
                        window.location.href = "/#/users";         
                        return;
                    }
                    if(r.name=='CLIENT'){
                        //alert("the user has caught");
                        window.location.href = "/#/intro";         
                        return;
                    }
                });
            }
        },
        created(){
            //alert("Created of after auth");
            let promise = axios.get(apiDomain+'/logineduser',{headers:getHeader()});
            return promise.then((response) => {
                const result = response.data;
                this.getLoginedUser(result);
                //alert(JSON.stringify(result));
                
            }).catch(error => {
                this.$router.push({name:'Login'});
            });
        }
    }
</script>