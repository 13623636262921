<template>
    <b-modal id="searchModal" hide-footer hide-header>
        <div class="w-100 text-right" >
            <svg 
            style="cursor: pointer;" @click="closeModal"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="d-block text-center login-title">
            {{ $t("oyu.landing.search").toUpperCase() }}
        </div>
        <div class="w-100 d-block">
            <autocomplete
                :search="search"
                :placeholder='$t("oyu.landing.search")'
                :aria-label='$t("oyu.landing.search")'
                :get-result-value="getResultValue"
                @submit="onSubmit"
            ></autocomplete>
        </div>
        
    </b-modal>
</template>
<script>
    //import firebase from 'firebase';
    import axios from 'axios';
    import {getHeader,loginUrl,apiDomain} from "@/config/config";
    import vue from 'vue';
    const authUser = {};
    const wikiUrl = 'https://en.wikipedia.org'
    const params = 'action=query&list=search&format=json&origin=*'
    export default {
        name:"LoginModal",
        data(){
            return {
                login:{
                    username:"",
                    password:""
                }
            }
        },
        methods:{
            search(input) {
               
                console.log(input)
                const url = `${apiDomain}/oyu/mobile/search`
                return new Promise((resolve) => {
                    axios.post(url,{"search":input},{headers:getHeader()})
                    .then((response) => {
                        if(response.data!=null){
                            resolve(response.data);
                        }else{
                            resolve([]);
                        }
                        
                    })
                    .catch((error) => {
                        // Handle any errors here
                        console.error('Axios Error:', error);
                        resolve([]); // Return an empty array in case of an error
                    });
                });
            },
            getResultValue(result) {
                if(result!=null)
                    return `${result.productName} ${result.productNameRu} ${result.productNameEn}`
            },
            onSubmit(result) {
                window.location.href = "/#/view/"+result.productId;    
                this.closeModal();   
            },
            closeModal(){
                this.$bvModal.hide('searchModal');
            },
            handleLoginFormSubmit(){
             //alert(JSON.stringify(header));
            axios.post(loginUrl,{
                'username':this.login.username,
                'password':this.login.password
                }, {headers: getHeader()})
                .then((res)=>{
                    if(res.status===200){
                        authUser.access_token=res.data.access_token;
                        authUser.refresh_token = res.data.refresh_token;
                        window.localStorage.setItem('authUser',JSON.stringify(authUser));
                        this.$router.push({name:'AfterAuth'});
                    }
                    if(res.status===401){
                        alert(vue.i18n.translate("oyu.landing.wrongLog"));
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert(vue.i18n.translate("oyu.landing.wrongLog"));
                });
            }
        },
    }
</script>
<style scoped>
    .login-footer span{
        cursor: pointer;
        color: var(--black, #000);
        font-family: "Helvetica Neue LT W1G";
        font-size: 12px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .social-btn-container{
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        margin-right: 10px;
    }
    .login-btn{
        background: var(--black, #000);
        color: var(--white, #FFF);

        /* Text md/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .label-text{
        color: var(--gray-700, #344054);
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .login-title{
        color: var(--black, #000);
        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
    .login-sub-title{
        margin-top: 5px;
        color: var(--black, #000);

        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 15px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
</style>