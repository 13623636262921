<template>
    <b-row class="m-0 p-0">
     <b-col cols="12">
        <!-- <object :data="pdfPath"
            type="application/pdf" width="100%" height="500px">
            <p>Unable to display PDF file. <a :href="pdfPath">Download</a> instead.</p>
        </object> -->
        <object :data="pdfPath"    
            width="100%"
            height="600">
          
        </object>
     </b-col>
    
   </b-row>
 </template>
 
 <script>
  import axios from 'axios';
  import {apiDomain,getHeader} from "@/config/config"; 
 
  import {EventBus} from "@/EventBus.js"
  import OyuService from "@/services/oyu_service.js";
  import vue from 'vue';
  export default {
     name:"PdfView",
    
     data() {
         return {
             sidebarOpen: false,
             param:null,
             pdfPath:null,


             pdfs:{
                policy:{
                    kz:"https://drive.google.com/file/d/1PV_PFQaOht1LTsO6nx4GT9ewmkqCxdSu/preview",
                    ru:"https://drive.google.com/file/d/1UNvHHuyyHb3fGtQLBRGAzq3rm9ocU3ko/preview",
                    en:"https://drive.google.com/file/d/1HSJy26ID3vUxi6z3yurksQkQABwfO3Cv/preview"
                },
                term:{
                    kz:"https://drive.google.com/file/d/1xyPSk9ACZneV4TyhcrTeg3WSYjK1A8j8/preview",
                    ru:"https://drive.google.com/file/d/1Wg3aE3akyyL8l7pvWTq5e6klRyUYd7Sw/preview",
                    en:"https://drive.google.com/file/d/112ySOSsShsCp_GBmM_jNKoEq_lJOjBgD/preview"
                },
                proc:{
                    kz:"https://drive.google.com/file/d/1skAE4SK1HRs2CXEtHJ_WDVHCNUxWnSg2/preview",
                    ru:"https://drive.google.com/file/d/1dIOkrfWR_AooSuqkasRgl8s7jWTarYuR/preview",
                    en:"https://drive.google.com/file/d/1elrt814BsHJZwuV2OBSXwiil6rAUJ3a7/preview"
                }
            }
         }
     },
     methods: { 
        
         back(){
             window.history.go(-1);
         },
         currentPdf(param=null){
            if(param=='policy'){
                if(vue.i18n.locale()=='en'){
                    this.pdfPath=this.pdfs.policy.en;
                }
                if(vue.i18n.locale()=='ru'){
                    this.pdfPath=this.pdfs.policy.ru;
                }
                if(vue.i18n.locale()=='kz'){
                    this.pdfPath=this.pdfs.policy.kz;
                }
            }
            if(param=='term'){
                if(vue.i18n.locale()=='en'){
                    this.pdfPath=this.pdfs.term.en;
                }
                if(vue.i18n.locale()=='ru'){
                    this.pdfPath=this.pdfs.term.ru;
                }
                if(vue.i18n.locale()=='kz'){
                    this.pdfPath=this.pdfs.term.kz;
                }
            }
            if(param=='proc'){
                if(vue.i18n.locale()=='en'){
                    this.pdfPath=this.pdfs.proc.en;
                }
                if(vue.i18n.locale()=='ru'){
                    this.pdfPath=this.pdfs.proc.ru;
                }
                if(vue.i18n.locale()=='kz'){
                    this.pdfPath=this.pdfs.proc.kz;
                }
            }
         }
     },
     created(){
       
        this.currentPdf(this.$route.params.path);    
     },
     watch: {
         $route(to, from) {
            this.currentPdf(this.$route.params.path);   
         }
     },
 //   mounted(){
 //      let t = this;
 //      EventBus.$on("productsByCat",(d)=>{
 //         t.homeProducts(Number(d));
 //      })
 //    }
 };
 
 </script>
 <style scoped>
     .back-ornaments{
         cursor: pointer;
         min-width: 318px;
 
         color: var(--black, #000);
 
         /* Text lg/Regular */
         font-family: Helvetica Neue LT W1G;
         font-size: 18px;
         font-style: normal;
         font-weight: 1000;
         line-height: normal;
     }
     .product-download{
         min-width: 318px;
 
 
         cursor: pointer;
         border: 1px solid var(--gray-200, #EAECF0);
         background: var(--gray-200, #EAECF0);
 
         /* Shadow/xs */
         box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
     }
     .product-download span{
         color: var(--gray-500, #667085);
         /* Text lg/Regular */
         font-family: "Helvetica Neue LT W1G";
         font-size: 18px;
         font-style: normal;
         font-weight: 1000;
         line-height: normal;
     }
     .product-view-sep{
         min-width: 318px;
         height: 1px;;
         background: #D0D5DD;
         
     }
     .view-side{
         background: var(--gray-50, #F9FAFB);
         min-height: 300px;
     }
     .product-name{
         min-width: 318px;
         color: var(--black, #000);
         /* Text lg/Semibold */
         font-family: "Helvetica Neue LT W1G";
         font-size: 18px;
         font-style: normal;
         font-weight: 1000;
         line-height: normal;
        
     }
     .sub-title{
         color: var(--black, #000);
         min-width: 318px;
         /* Text sm/Semibold */
         font-family: "Helvetica Neue LT W1G";
         font-size: 16px;
         font-style: normal;
         font-weight: 1000;
         line-height: normal;
     }
 
 </style>
  
 
 
 