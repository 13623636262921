<template>
    <b-row class="m-0 p-0">
        <b-col class="m-0 p-0" lg="12" cols="12">
            <span class="d-inline-block ml-5 mt-3 acc-title">
                {{ 
                 loginedUser.name.length>0 ?  loginedUser.name : loginedUser.displayName.length>0 ? loginedUser.displayName : 
                 loginedUser.fbId.length>0 ? 
                 `Facebook: ${loginedUser.fbId}` : loginedUser.appId.length>0 ? `Apple: ${loginedUser.appleId}` : loginedUser.userName
                }}
                <!-- {{ loginedUser }} -->
            </span>
            <div class="d-block  mt-3" style="height: 1px;background-color: #D0D5DD;"></div>
        </b-col>
        
        <b-col lg="4" class="m-0 p-0 pt-4 pb-5"  style="border-right:1px solid #D0D5DD;">
            <div class="ml-5">

                <div><a class="acc-item" href="/#/info">{{ $t("oyu.landing.acc.title") }}</a></div>
                <div><a class="acc-item" href="/#/chosen">{{ $t("oyu.landing.acc.choosen") }}</a></div>

                <div class="mt-4"><a class="acc-item" @click="logout" href="javascript:void(0)">{{ $t("oyu.landing.acc.logout") }}</a></div>
            </div>
            <!-- <b-button @click="logout"> LOGOUT {{ loginedUser }}</b-button> -->
        </b-col>
        <b-col  lg="8" class="m-0 p-0 pt-4">
            <div class="pl-3">
                <router-view></router-view>    
            </div>
        </b-col>
   </b-row>
 </template>
 
 <script>
  import axios from 'axios';
  import {apiDomain,getHeader} from "@/config/config";  
  import {EventBus} from "@/EventBus.js"
  import OyuService from "@/services/oyu_service.js";
  import { isMobile } from 'mobile-device-detect';
  import {mapState} from 'vuex';
  export default {
     name:"Account",
     data() {
         return {
             sidebarOpen: false,
           
         }
     },
     methods: { 
        async logout(){
           
            const service = new OyuService(apiDomain,getHeader());
            service.logoutSystem();
        }, 
         back(){
             window.history.go(-1);
         },
     },
     computed:{
        ...mapState([
            'loginedUser'
        ]),
    },
    mounted(){
        if(isMobile){
            this.sidebarOpen=false;
        }else{
            this.sidebarOpen=true;
        }
    }
 };
 
 </script>
 <style scoped>
    a.acc-item{
        color: var(--gray-500, #667085);
        /* Text lg/Regular */
        font-family: Helvetica Neue LT W1G;
        font-size: 18px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    a.acc-item:hover{
        color : #000;
        text-decoration: none;
    }
    .acc-item{
        color: var(--gray-500, #667085);
        /* Text lg/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }

    .acc-title{
        color: var(--Black, #000);

        /* Display xs/Regular */
        font-family: Helvetica Neue LT W1G;
        font-size: 24px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
 </style>
  
 
 
 