<template>
    <div class="w-100 position-relative banner-container  overflow-hidden" style="min-height: 394px;">
        <img class="position-absolute" style="right:0"
         src="@/assets/banner_image.png">
        <div class="position-absolute" 
            style="bottom:13%;left:40px;">
            <span class="chooseP" v-html="$t('oyu.landing.chooseProduct')"></span>
            <div v-if="!loading" class="choose-button text-center pt-2 mt-3" @click="findSuitable">
                {{ $t("oyu.landing.chooseP") }}
            </div>
            <div v-if="loading" class="choose-button text-center pt-1 mt-3 text-light">
                <svg v-if="loading" version="1.1"  id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <rect x="0" y="10" width="4" height="10" fill="#fff" opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="8" y="10" width="4" height="10" fill="#fff"  opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="16" y="10" width="4" height="10" fill="#fff"  opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                </svg>
            </div>
        </div>
        <b-modal id="questionModal"  hide-footer hide-header @shown="questionInit('questionModal')" @hidden="questionHidden" >
            <div class="w-100 text-right" >
                <svg 
                style="cursor: pointer;" @click="closeModal('questionModal')"
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="d-block">
                <h5 class="mb-5">
                    {{ dData.question }}
                </h5>
                <div class="d-block" v-for="(a,ai) in dData.answers" :key="ai">
                    <b-button size="sm" class="mt-2" block variant="outline-secondary" @click="nextQuestion(a.answerId)">
                        {{ 
                            $i18n.locale()=='kz' ?  a.answer : $i18n.locale()=='ru'
                            ? a.answerRu : $i18n.locale()=='en' ? a.answerEn : "" 
                        }}
                    </b-button>
                </div>
            </div>
        </b-modal>

        <b-modal id="productModal"  hide-footer hide-header >
            <div class="w-100 text-right" >
                <svg 
                style="cursor: pointer;" @click="closeModal('productModal')"
                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="d-block mt-5">
                <div class="d-block " v-if="foundOrnament && foundOrnament!=null">
                    <h5 class="ml-5 mb-2">
                        {{ $t("oyu.landing.privateOrnament") }}
                    </h5>
                    <div class="p-3 mt-2 mx-5 mb-2 text-center" style="border:1px solid #D0D5DD">
                        <img style="width: 150px" 
                        :class="[rotating ? 'rotate-animation' : '', rotating ? 'paused' : '']"
                        :src="'https://www.oyuapp.com/api/img?imagePath='+foundOrnament.image">
                    </div>
                    <div class="px-3 mx-5 d-block text-center">
                        {{ 
                            $i18n.locale()=='kz' ? foundOrnament.productName
                            : $i18n.locale()=='ru' ? foundOrnament.productNameRu 
                            : $i18n.locale()=='en' ? foundOrnament.productNameEn : "" 
                        }}    
                    </div>
                    <div class="mx-5 d-block mt-3">
                        <div class="py-2 px-3  d-inline-block"
                            @click="likeProduct(foundOrnament)"  
                            style="cursor: pointer; border:1px solid #D0D5DD">
                            <img src="@/assets/love.png" width="20" height="18" v-if="foundOrnament.isLiked==0">
                            <img src="@/assets/loved.png" width="20" height="18" v-if="foundOrnament.isLiked>0">
                        </div>
                        <div @click="downloadProduct(foundOrnament)" class="py-2 px-5  d-inline-block ml-auto float-right"

                            style="cursor: pointer; border:1px solid #D0D5DD">
                            {{ $t("oyu.landing.acc.download") }}
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import vue from 'vue';
    import OyuService from "@/services/oyu_service.js";
    import SubModal from "@/components/lmslanding/SubModal"
    export default{
        name:"Banner",
        data(){
            return {
                loading:false,
                qData:[],
                dData:{
                    question:"",
                    answers:[]
                },
                groupId : 0,
                qq:null,
                shouldFind:false,
                foundOrnament:null,
                rotating:false,
                prev:null
               
            }
        },
        methods:{
            downloadProduct(found){
                let obj = found;
                if(obj!=null){
                    if(this.loginedUser){
                        if(Number(this.loginedUser.id)>0){
                            if(Number(obj.isActive)==1){
                                const service = new OyuService(apiDomain,getHeader());
                                service.downloadProduct({"image":obj.image});
                            }else{
                                this.$bvModal.show('subModal');
                            }
                        }else{
                            alert(vue.i18n.translate("oyu.landing.operation"));
                        }
                    }else{
                        alert(vue.i18n.translate("oyu.landing.operation"));
                    }
                }
               
            }, 
            async likeProduct(obj){
                const service = new OyuService(apiDomain,getHeader());
                try {
                    const data = await service.likeProduct({"productId":obj.productId});
                    if(data.isSuccess){ 
                        if(obj.isLiked>0){
                            obj.isLiked=0;
                        }else{
                            obj.isLiked=1;
                        }
                    }
                } catch (error) {
                   alert(error.message);
                }
            }, 
            startRotation() {
                this.rotating = true;
                setTimeout(() => {
                    this.rotating = false;
                }, 3000); // Stop rotation after 2 seconds
            },
            closeModal(modalId){
                this.$bvModal.hide(modalId);
            },
            nextQuestion(id){
                if(!this.shouldFind){
                    if(this.groupId==0){
                        this.groupId=id;
                    }
                    this.qData.forEach(q=>{
                        if(Number(q.groupId)==Number(this.groupId)){
                            this.dData={
                                question:"",
                                answers:[]
                            };
                            if(this.qq==null){
                                this.qq=q.questions[0];
                            }else{
                                this.prev=this.qq;
                                this.qq= q.questions[1];
                                this.shouldFind = true;
                            }
                            let aa = this.qq.answers;
                            console.info("answers : ",aa);
                            let l = vue.i18n.locale();
                            let question = l =='kz' ? this.qq.question : l=='ru' ? this.qq.questionRu : l=='en'  ? this.qq.questionEn : ""
                            this.dData.question=question;
                            this.dData.answers=aa;
                            return ;
                        }
                    });
                }else{
                    axios.post(apiDomain+'/oyu/product/one',{
                        "firstAnswerId":this.prev.answers[0].answerId,
                        "secondAnswerId":id
                    },{headers:getHeader()})
                    .then((res)=>{

                        this.foundOrnament=res.data;
                        this.$bvModal.hide("questionModal");
                        this.$bvModal.show("productModal");
                        this.startRotation();
                       
                    })
                    .catch(error => {
                        this.loading=false;
                        console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Қате туралы ақпарат',
                            autoHideDelay: 1000
                        })
                    })
                }
            },
           
            questionInit(){
                this.dData.question = vue.i18n.translate("oyu.landing.firstQuestion");
                this.foundOrnament=null;
            },
            questionHidden(){
                this.loading=false,
                this.qData=[],
                this.dData={
                    question:"",
                    answers:[]
                },
                this.groupId = 0;
                this.qq = null;
                this.shouldFind=false;
            },
            findSuitable() {
                this.loading=true;
                axios.post(apiDomain+'/oyu/qgroup/readformobile',{},{headers:getHeader()})
                .then((res)=>{
                    this.loading=false;
                    this.qData=res.data;
                    console.info("qData ",this.qData);
                    //creating answer object
                    this.qData.forEach(q=>{
                        this.dData.answers.push(
                            {
                                answerId : q.groupId,
                                answer:q.groupNameKz,
                                answerRu:q.groupNameRu,
                                answerEn:q.groupNameEn
                            }
                        );
                    });
                    this.$bvModal.show("questionModal");
                })
                .catch(error => {
                    this.loading=false;
                    if(error.response.status==401){
                        const service = new OyuService(apiDomain,getHeader());
                        service.logoutSystem();
                    }
                    //console.log(error.message)
                    this.$bvToast.toast(error.message, {
                        title: 'Error title',
                        autoHideDelay: 5000
                    })
                })
            },
        },
        computed:{
            ...mapState([
                'loginedUser'
            ]),
          
        }
    }
</script>   
<style scoped>
    .rotate-animation {
        animation: rotate 2s infinite;
    }

    @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
    .choose-button{
        cursor: pointer;
        width: 168px;
        height: 36px;
        background-image: url('~@/assets/choose_btn.png');

        color: var(--system-background-light-primary, #FFF);
        /* Text md/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .chooseP{
        color: var(--white, #FFF);
        
        font-size: 30px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .banner-container{
        background-color: #000;
    }
</style>