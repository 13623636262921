<template>
    <b-row class="m-0 p-0">
        <b-col lg="12" class="m-0 p-0">
            <b-row v-for="(p,pi) in chosen" :key="pi" class="mb-4">
                <b-col lg="5" cols="3">
                    <span class="d-inline-block liked-item-name">
                        {{ $i18n.locale()=='kz' ? p.productName :  $i18n.locale()=='ru' ? p.productNameRu : p.productNameEn }}
                    </span>
                </b-col>
                <b-col lg="2" cols="3">
                    <img style="max-width:80px;height: 40px;" :src="'https://www.oyuapp.com/api/img?imagePath='+p.image">
                    
                </b-col>
                <b-col lg="2" class="text-right" cols="2">
                   
                    <div @click="likeProduct(p,pi)">
                        
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.481 4.57653C18.458 2.47457 15.1796 2.47457 13.1566 4.57653L11.9991 5.77926L10.8416 4.57653C8.81861 2.47457 5.54016 2.47457 3.51721 4.57653C1.49426 6.67848 1.49426 10.085 3.51721 12.1869L4.67474 13.3897L11.9991 21.0001L20.4828 12.185C22.5057 10.0831 22.5057 6.6766 20.4828 4.57465L20.481 4.57653Z" fill="black"/>
                        </svg>
                    </div>
                </b-col>
                <b-col lg="3" class="text-right" cols="4">
                    <div class="download-btn" @click="downloadProduct(p.productId)" style="cursor: pointer;">
                        <!-- {{ $t("oyu.landing.acc.download") }} -->
                        <svg fill="#000000" width="20px" height="20px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 5.222V0h2v5.193l1.107-1.107L11.52 5.5 7.986 9.036 4.45 5.5l1.414-1.414L7 5.222zM16 11v5H0v-5h2v3h12v-3h2z" fill-rule="evenodd"></path> </g></svg>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>
<script>
    import {mapState} from 'vuex';
    import axios from 'axios';
    import {apiDomain,getHeader,loginUrl} from "@/config/config";
    import OyuService from "@/services/oyu_service.js";
    export default {
        name:"AccInfo",
        data(){
            return {
                chosen:[]
            }
        },
        methods:{
            async likeProduct(obj,i){
                
                const service = new OyuService(apiDomain,getHeader());
                try {
                    const data = await service.likeProduct({"productId":obj.productId});
                    if(data.isSuccess){ 
                        // if(obj.isLiked>0){
                        //     obj.isLiked=0;
                        // }else{
                        //     obj.isLiked=1;
                        // }
                        this.chosen.splice(i,1);

                    }
                } catch (error) {
                    alert(error.message);
                }
            }, 
            downloadProduct(productId){
                window.location.href = "/#/view/"+productId;         
            },
            chosenP(){
                axios.post(apiDomain+'/oyu/product/liked',{},{headers:getHeader()})
                .then(response=>{
                    this.chosen=response.data;
                })
                .catch(error => {
                    alert(error);
                }) 
            }
        },
        created(){
            this.chosenP();
        },
        computed:{
            ...mapState([
                'loginedUser'
            ]),
        }
    }
</script>
<style scoped>
    .download-btn{
        display: flex;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border: 1px solid var(--Black, #000);
        background: var(--White, #FFF);
    }
    .liked-item-name{
        color: var(--Black, #000);
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .inf-title{
        color: var(--gray-500, #667085);

        /* Text lg/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .inf-acc{
        color: var(--Black, #000);

        /* Display xs/Regular */
        font-family: Helvetica Neue LT W1G;
        font-size: 20px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
</style>