<template>
    <b-row class="m-0 p-0">
        <b-col lg="12" class="m-0 p-0">
            <div class="w-100">
                <span class="inf-title">
                    {{ $t("oyu.landing.acc.private") }}
                </span>
            </div>
            <div class="inf-acc pt-3">
                {{ loginedUser.name }}<br/>
                {{ loginedUser.email }}
            </div>
        </b-col>
        <b-col lg="12" class="m-0 p-0">
            <div class="d-block  mt-5 mb-2" style="height: 1px;background-color: #D0D5DD;"></div>
        </b-col>
        <b-col lg="12" class="m-0 p-0">
            <div class="w-100">
                <span class="d-inline-block inf-title">
                    {{ $t("oyu.landing.acc.sub") }}
                </span>
                <span class="inf-title d-inline-block float-right" @click="subscribe" style="cursor: pointer;" >
                    {{ $t("oyu.landing.acc.manageSub")  }}
                </span>
            </div>
            
            <div class="inf-acc pt-3">
                <!-- жазылым: 5990тг / 6 айға<br>
                мерзімі: 11/08/23 -->
                {{ plan.planId>0 ? 
                    $i18n.locale()=='kz' ? plan.planText : $i18n.locale()=='ru' ? plan.planTextRu : plan.planTextEn
                 : $t("oyu.landing.noSub") }}
            </div>

            <SubModal></SubModal>
        </b-col>
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {mapState} from 'vuex';
    import {apiDomain,getHeader} from "@/config/config"; 
    import SubModal from "@/components/lmslanding/SubModal"
    export default {
        name:"AccInfo",
        components:{
            SubModal
        },
        data(){
            return {
                plan:{}
            }
        },
        methods:{
            subscribe(){
                this.$bvModal.show('subModal');
            },
            userPlan(){
                axios.post(apiDomain+'/oyu/userplan',{},{headers:getHeader()})
                .then(response=>{ 
                    this.plan=response.data;
                })
                .catch((error) => {
                    alert(error.message);
                }) 
            }
        },
        computed:{
            ...mapState([
                'loginedUser'
            ]),
        },
        created(){
            this.userPlan();
        }
    }
</script>
<style scoped>
    .inf-title{
        color: var(--gray-500, #667085);

        /* Text lg/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .inf-acc{
        color: var(--Black, #000);

        /* Display xs/Regular */
        font-family: Helvetica Neue LT W1G;
        font-size: 20px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
</style>