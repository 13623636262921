<template>
    <b-modal id="loginModal" hide-footer hide-header>
        <div class="w-100 text-right" >
            <svg 
            style="cursor: pointer;" @click="closeModal"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="d-block text-center login-title">
            {{ $t("oyu.landing.loginTitle") }}
        </div>
        <div class="d-block text-center login-sub-title">
            {{ $t("oyu.landing.loginSubTitle") }}
        </div>
        <form class="auth-form mt-5" v-on:submit.prevent="handleLoginFormSubmit"> 
            <div class="form-group mb-2">
            <label class="d-block text-left label-text" 
            for="inputUser">{{ $t("oyu.landing.email") }}</label> 
            <input 
                :placeholder="$t('oyu.landing.email')"
                type="email" 
                id="inputUser" 
                class="form-control form-control-md label-text" 
                required="" 
                v-model="login.username"
                autofocus="">
            </div>
            <div class="form-group mb-2">
                <label class="d-block text-left label-text" 
                for="inputPassword">{{ $t("oyu.landing.password") }}</label>
                <input 
                    :placeholder="$t('oyu.landing.password')"
                    type="password" 
                    id="inputPassword" 
                    class="form-control form-control-md label-text"
                    required=""
                    v-model="login.password"
                >
            </div>
            <div class="form-group pt-3">
                <button class="login-btn w-100 text-center py-3 border-0"  type="submit">
                    {{ $t("oyu.landing.login") }}
                </button>
            </div>
            <div class="d-block text-center">
                <span class="d-inline-block py-2 px-4 social-btn-container" style="cursor: pointer;" @click="googleSignin()">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_482_4850)">
                        <path d="M24.266 12.2764C24.266 11.4607 24.1999 10.6406 24.0588 9.83807H12.74V14.4591H19.2217C18.9528 15.9494 18.0885 17.2678 16.823 18.1056V21.1039H20.69C22.9608 19.0139 24.266 15.9274 24.266 12.2764Z" fill="black"/>
                        <path d="M12.7401 24.0008C15.9766 24.0008 18.7059 22.9382 20.6945 21.1039L16.8276 18.1055C15.7517 18.8375 14.3627 19.252 12.7445 19.252C9.61388 19.252 6.95946 17.1399 6.00705 14.3003H2.0166V17.3912C4.05371 21.4434 8.2029 24.0008 12.7401 24.0008Z" fill="black"/>
                        <path d="M6.00253 14.3003C5.49987 12.8099 5.49987 11.1961 6.00253 9.70575V6.61481H2.01649C0.31449 10.0056 0.31449 14.0004 2.01649 17.3912L6.00253 14.3003Z" fill="black"/>
                        <path d="M12.7401 4.74966C14.4509 4.7232 16.1044 5.36697 17.3434 6.54867L20.7695 3.12262C18.6001 1.0855 15.7208 -0.034466 12.7401 0.000808666C8.2029 0.000808666 4.05371 2.55822 2.0166 6.61481L6.00264 9.70575C6.95064 6.86173 9.60947 4.74966 12.7401 4.74966Z" fill="black"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_482_4850">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </span>
                <span class="d-inline-block py-2 px-4 social-btn-container" style="cursor: pointer;">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_482_4856)">
                        <path d="M24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 17.9895 4.8882 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1576 4.6875C16.4701 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.84 7.875 14.375 8.80008 14.375 9.75V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1118 22.954 24.5 17.9895 24.5 12Z" fill="black"/>
                        <path d="M17.1711 15.4688L17.7031 12H14.375V9.75C14.375 8.80102 14.84 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1576 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.8674 24.0486 13.1326 24.0486 14.375 23.8542V15.4688H17.1711Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_482_4856">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                        </defs>
                    </svg>

                </span>
                <!-- <span class="d-inline-block py-2 px-4 social-btn-container" style="cursor: pointer;">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.3426 17.1449C21.0099 17.9135 20.6161 18.6211 20.1598 19.2715C19.5378 20.1583 19.0286 20.7721 18.6361 21.113C18.0277 21.6724 17.3759 21.959 16.6779 21.9753C16.1768 21.9753 15.5725 21.8327 14.8691 21.5434C14.1633 21.2555 13.5148 21.113 12.9217 21.113C12.2998 21.113 11.6327 21.2555 10.9193 21.5434C10.2047 21.8327 9.62904 21.9834 9.18892 21.9984C8.51957 22.0269 7.85239 21.7322 7.18644 21.113C6.76139 20.7422 6.22974 20.1067 5.59285 19.2063C4.90951 18.2449 4.34772 17.13 3.9076 15.8589C3.43624 14.486 3.19995 13.1565 3.19995 11.8694C3.19995 10.3951 3.51853 9.12345 4.15665 8.05784C4.65815 7.20191 5.32533 6.52672 6.16035 6.03105C6.99537 5.53539 7.89761 5.2828 8.86925 5.26664C9.4009 5.26664 10.0981 5.43109 10.9645 5.75429C11.8284 6.07858 12.3832 6.24303 12.6264 6.24303C12.8082 6.24303 13.4245 6.05074 14.4692 5.66738C15.4571 5.31186 16.2909 5.16466 16.974 5.22264C18.8249 5.37202 20.2155 6.10167 21.1402 7.41619C19.4849 8.4192 18.666 9.82403 18.6823 11.6262C18.6972 13.03 19.2065 14.1981 20.2073 15.1256C20.6609 15.5561 21.1674 15.8888 21.731 16.1251C21.6087 16.4795 21.4797 16.819 21.3426 17.1449ZM17.0975 0.440369C17.0975 1.54062 16.6956 2.56792 15.8944 3.51878C14.9275 4.64917 13.758 5.30236 12.4898 5.19929C12.4736 5.06729 12.4642 4.92837 12.4642 4.78239C12.4642 3.72615 12.924 2.59576 13.7406 1.67152C14.1483 1.20356 14.6667 0.814453 15.2955 0.504058C15.9229 0.198295 16.5163 0.0292007 17.0744 0.000244141C17.0907 0.147331 17.0975 0.294426 17.0975 0.440355V0.440369Z" fill="black"/>
                    </svg>
                </span> -->
            </div>
            <div class="login-footer pt-5">
                <span class="mr-5" @click="showRegModal">
                    {{ $t("oyu.landing.freeReg") }}
                </span>
                <span style="cursor: pointer;" @click="showResetModal()">
                    {{ $t("oyu.landing.forgotPassword") }}
                </span>
            </div>
        </form>
    </b-modal>
</template>
<script>
    //import firebase from 'firebase';
    import axios from 'axios';
    import {getHeader,loginUrl,apiDomain} from "@/config/config";
    import vue from 'vue';
    import { initializeApp } from "firebase/app";
    import {getAuth,GoogleAuthProvider,signInWithPopup,getRedirectResult} from "firebase/auth";
    const authUser = {};
    
    export default {
        name:"LoginModal",
        data(){
            return {
                login:{
                    username:"",
                    password:""
                }
            }
        },
        methods:{
            googleSignin(){
                const firebaseConfig = {
                    apiKey: "AIzaSyCrZ8MryB307UObCdYkDksM2fjvRQICcnc",
                    authDomain: "sign-in-5c2d9.firebaseapp.com",
                    projectId: "sign-in-5c2d9",
                    storageBucket: "sign-in-5c2d9.appspot.com",
                    messagingSenderId: "269642373913",
                    appId: "1:269642373913:web:44975ee9121f38621f8388"
                };
                // Initialize Firebase
                const app = initializeApp(firebaseConfig);
                const auth = getAuth(app);
                const provider = new GoogleAuthProvider(app);

                signInWithPopup(auth,provider).then((result)=>{
                    const user = result.user;
                    
                    axios.post(apiDomain+'/oyu/client/logingoogle',{
                        "displayName":user.displayName,
                        "email":user.email
                    },{headers:getHeader()})
                    .then((res)=>{
                        if(res.status===200){
                            authUser.access_token=res.data.access_token;
                            authUser.refresh_token = res.data.refresh_token;
                            window.localStorage.setItem('authUser',JSON.stringify(authUser));
                            this.$router.push({name:'AfterAuth'});
                        }
                        if(res.status===401){
                            alert(vue.i18n.translate("oyu.landing.wrongLog"));
                        }
                    })
                    .catch(error => {
                        this.loading=false;
                        if(error.response.status==401){
                            const service = new OyuService(apiDomain,getHeader());
                            service.logoutSystem();
                        }
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Error title',
                            autoHideDelay: 5000
                        })
                    })
                    


                }).catch((error)=>{
                    console.log(error);
                });
            },
            showResetModal(){
                this.$bvModal.hide('loginModal');
                this.$bvModal.hide('regModal');

                this.$bvModal.show('resetModal');
            },
            showRegModal(){
                this.$bvModal.hide('loginModal');
                this.$bvModal.show('regModal');
            },
            closeModal(){
                this.$bvModal.hide('loginModal');
            },
            handleLoginFormSubmit(){
             //alert(JSON.stringify(header));
            axios.post(loginUrl,{
                'username':this.login.username,
                'password':this.login.password
                }, {headers: getHeader()})
                .then((res)=>{
                    if(res.status===200){
                        authUser.access_token=res.data.access_token;
                        authUser.refresh_token = res.data.refresh_token;
                        window.localStorage.setItem('authUser',JSON.stringify(authUser));
                        this.$router.push({name:'AfterAuth'});
                    }
                    if(res.status===401){
                        alert(vue.i18n.translate("oyu.landing.wrongLog"));
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert(vue.i18n.translate("oyu.landing.wrongLog"));
                });
            }
        },
    }
</script>
<style scoped>
    .login-footer span{
        cursor: pointer;
        color: var(--black, #000);
        font-family: "Helvetica Neue LT W1G";
        font-size: 12px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .social-btn-container{
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        margin-right: 10px;
    }
    .login-btn{
        background: var(--black, #000);
        color: var(--white, #FFF);

        /* Text md/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .label-text{
        color: var(--gray-700, #344054);
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .login-title{
        color: var(--black, #000);
        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
    .login-sub-title{
        margin-top: 5px;
        color: var(--black, #000);

        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 15px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
</style>