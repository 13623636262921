<template>
    <b-row class="pt-5 pb-4 text-light">
       <!-- <b-col lg="12">
           
           <div>
                <b-card bg-variant="dark" img-width="65%" :img-src="''+fileServer()+'/server/image?imagePath='+course.imagePath"  img-right>
                    <b-card-text>
                        <h4>{{course.courseName}}</h4>
                        {{course.introduction}}
                    </b-card-text>
                </b-card>
            </div>
       </b-col> -->
       <!-- <b-col lg="12">
           <hr style=" border-top: 1px solid #22262f;"/>
       </b-col> -->
       <b-col lg="4">
            <b-row>
                <b-col lg="12" style="position:relative; overflow-y:auto; height:100vh"> 
                    
                    <b-card bg-variant="dark" class="mb-1">
                        <!-- <h5 class="text-uppercase mb-3" style="font-family:cubano,sans-serif !">{{cm.moduleName}}</h5> -->
                        <b-row class="pb-1" >
                            <b-col lg="2" class="align-self-center text-right">
                                <div style="font-size: 3rem;" class="pl-3">
                                    <b-icon icon="lightning" variant="warning"  class=" p-2  mt-1"></b-icon>
                                </div>
                            </b-col>
                            <b-col lg="10">
                                <b-list-group>
                                    <b-list-group-item style="background:#282c34 !important;"  :href="'/#/course/'+courseId"  class="flex-column align-items-start shadow-lg">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1 mr-auto" style="color:#f8f9fa !important;">{{course.courseName}}</h5>
                                        </div>
                                        <small class="mb-1" style="color:#f8f9fa !important;">
                                            {{course.description}}
                                        </small>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>    
                        </b-row>
                    </b-card>

                    <b-card bg-variant="dark" class="mb-1" v-for="(cm , cmi) in course.modules" :key="cmi">
                        <h5 class="text-uppercase mb-3" style="font-family:cubano,sans-serif !">{{cm.moduleName}}</h5>
                        

                        <b-row class="pb-1" v-for="(l,li) in cm.mixedComponents" :key="li+8">
                            <b-col lg="2" class="align-self-center text-right">
                                <div style="font-size: 3rem;" class="pl-3">
                                    <b-icon icon="camera-video"
                                    v-if="l.compType==3"
                                     variant="light"  class=" p-2  mt-1"></b-icon>
                                   
                                    <b-icon icon="book"
                                    v-if="l.compType==1"
                                     variant="light"  class=" p-2  mt-1"></b-icon>
                                    <b-icon  icon="diagram-3"
                                    v-if="l.compType==0"
                                     variant="light"  class=" p-2  mt-1"></b-icon>
                                </div>
                            </b-col>
                            <b-col lg="10">
                                <b-list-group>
                                    <b-list-group-item style="background:#282c34 !important;" 
                                        href="javascript:void(0)"
                                        @click="viewContent(courseId,l.compType,l.compId)"
                                        v-if="l.compType==3" 
                                          class="flex-column align-items-start shadow-lg">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1 mr-auto" style="color:#f8f9fa !important;">{{l.compName}}</h5>
                                        </div>
                                        <small class="mb-1" style="color:#f8f9fa !important;">
                                            {{l.description}}
                                        </small>
                                    </b-list-group-item>
                                    <b-list-group-item variant="dark" style="background:#282c34 !important;" 
                                        href="javascript:void(0)"
                                        v-if="l.compType==1"  
                                        @click="viewContent(courseId,l.compType,l.compId)"
                                        class="flex-column align-items-start">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1 mr-auto" style="color:#f8f9fa !important;">{{l.compName}}</h5>
                                        </div>
                                        <small class="mb-1" style="color:#f8f9fa !important;">  
                                            {{l.description}}
                                        </small>
                                    </b-list-group-item>

                                    <b-list-group-item  style="background:#282c34 !important;"  
                                        href="javascript:void(0)"
                                        @click="viewContent(courseId,l.compType,l.compId)"
                                        v-if="l.compType==0"    
                                        class="flex-column align-items-start shadow-lg">
                                        <div class="d-flex w-100 justify-content-between">
                                            <h5 class="mb-1 mr-auto text-center" style="color:#f8f9fa !important;">{{l.compName}}</h5>
                                        </div>
                                        <small class="mb-1" style="color:#f8f9fa !important;">
                                            нажмите здесь, чтобы начать лабораторию
                                        </small>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row>                        
                    </b-card>
                </b-col>
            </b-row>
       </b-col>
       <b-col lg="8" style="position:relative; overflow-y:auto; height:100vh">
           <b-card bg-variant="dark" class="mb-1" v-if="first==1">    
                <b-list-group>
                
                    <div class="d-flex w-100 justify-content-between">
                        <h4 class="mb-1 mr-auto">{{course.courseName}}</h4>
                    </div>
                    <p class="mb-1">
                        {{course.introduction}}
                    </p>
                    <div class="w-100 mb-1" v-for="(l,li) in course.details" :key="li">
                        <div v-html="l.description" class="ql-container ql-editor ql-bubble" style="color:white !important;" v-if="l.compType==2"></div>
                        <b-img-lazy 
                            center
                            v-if="l.compType==1"
                            fluid
                            height=200
                            rounded
                            :src="''+fileServer()+'/server/image?imagePath='+l.compPath"
                        ></b-img-lazy>   
                        <div class="w-100" v-if="l.compType==3">
                            <iframe class="w-100" style="height:600px" :src="'https://www.youtube.com/embed/'+l.description+'?rel=0'" allowfullscreen></iframe>
                        </div>   
                        <CodeEditor
                            v-if="l.compType==5"
                            :display_language="false" 
                            :read_only="true" width="100%" :value="l.description"  z_index="99900000000"></CodeEditor>
                    </div>
                </b-list-group>
            </b-card>
           <router-view></router-view>
       </b-col>
       <b-alert
            v-model="showBottom"
            class="position-fixed fixed-bottom m-0 rounded-0"
            dismissible
            variant="secondary"
            style="z-index: 20000;"
        >   
            <center>
                <p class="text-center text-primary text-uppercase font-weight-bold">{{$t("lms.mustLoginError")}}</p>
            </center>
        </b-alert>
        <b-alert
            v-model="showPriceAlert"
            class="position-fixed fixed-bottom m-0 rounded-0"
            dismissible
            variant="secondary"
            style="z-index: 20000;"
        >   
            <center>
                <p class="text-center text-primary text-uppercase font-weight-bold">This is not free course you have to be assigned by system administrator !!!</p>
            </center>
        </b-alert>
    </b-row>
</template>
<script>


import axios from 'axios';
import {apiDomain,getHeader} from "@/config/config";
import {EventBus} from "@/EventBus.js"
import {mapState} from 'vuex';
import vue from 'vue'
export default {
    name:"Course",
    data(){
       return {
           courseId : 0,
           course:{
               courseId:0
           },
           first:1,
           showBottom:false,
           showPriceAlert:false,
       }
       
    },
    methods: {
        viewContent(courseId,type,compId){
            if(this.hasRole("STUDENT")){
                axios.post(apiDomain+'/landing/hasright',{courseId:Number(courseId)},{headers:getHeader()})
                .then(response=>{
                    let h=response.data;
                    if(h=="hasright"){
                        let url = null;
                        if(type==3) url="/#/course/"+courseId+"/contentview/1/"+compId
                        if(type==1) url="/#/course/"+courseId+"/contentview/2/"+compId
                        if(type==0) url="/#/labintro/"+compId
                        if(url!=null) window.location.href = url;
                        return;
                    }
                    this.showPriceAlert=true;
                    this.showBottom=false;
                    return 
                })
                .catch(error => {
                    //console.log(error.message)
                    this.$bvToast.toast(error.message, {
                        title: 'error',
                        autoHideDelay: 5000
                    })
                })    
                return
            }else{
                this.showBottom=true;
                this.showPriceAlert=false;
            }
        },
        fileServer(){
            return apiDomain;
        },
        startLab(moduleId){
            window.location.href = "/#/lab/"+moduleId;
        },
        currentCourse(courseId){
            axios.post(apiDomain+'/landing/currentcourse',{courseId:Number(courseId)},{headers:getHeader()})
            .then(response=>{
                this.course=response.data;
            })
            .catch(error => {
                //console.log(error.message)
                this.$bvToast.toast(error.message, {
                    title: 'error',
                    autoHideDelay: 5000
                })
            }) 
        },
        hasRole(roleName){
            if(this.loginedUser.userRoles){
                let roleNames = this.loginedUser.userRoles;
                if(roleNames.length>0){
                //alert(JSON.stringify(roleNames));
                for(let r=0;r<roleNames.length;r++){
                    if(roleNames[r].name===roleName){
                        return true;
                    }
                }
                }
                else{
                    return false;
                }
            }
            return false;
        }
    },
    created(){
        this.courseId = this.$route.params.courseId;
        this.currentCourse(this.courseId);        
    },
    watch: {
        $route(to, from) {
            //console.log(to);
            this.first=1;
            this.courseId = this.$route.params.courseId;
            this.currentCourse(this.courseId);        
        }
    },
    computed:{
        ...mapState([
            'loginedUser'
        ]),
    },
    mounted(){
        let t = this;
        EventBus.$on("contentView",(d)=>{
            t.first=d;
        })
        EventBus.$on("noLogin",(d)=>{
            //this.showBottom=true;
            window.location.href = "/#/course/"+t.courseId;
            // this.$bvToast.toast(vue.i18n.translate("lms.mustLoginError"), {
            //     title:vue.i18n.translate("system.errorTitle"),
            //     toaster: "b-toaster-bottom-left",
            //     appendToast:false
            //     //autoHideDelay: 5000
            // })
            
        })
    } 
}
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>