<template>
    <b-modal size="lg" id="subModal" hide-footer hide-header @hidden="clearForm">
        <div class="w-100 text-right" >
            <svg 
            style="cursor: pointer;" @click="closeModal"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="d-block text-center login-title">
            OYU APP Pro
        </div>
        <div class="d-block text-center login-sub-title">
            {{ $t("oyu.landing.slideDesc1") }}
            <hr>
        </div>
        <div class="auth-form mt-2" v-if="!payment">
            <div class="w-100 text-center" v-for="(p,pi) in plans" :key="pi">
                <span class="price-item">{{ $i18n.locale()=='kz' ? p.planText : $i18n.locale()=='ru' ? p.planTextRu : p.planTextEn }}</span>
                <div class="form-group my-2">
                    <button class="login-btn w-100 text-center py-3 border-0"  @click="subPlan(p)">
                        {{ $t("oyu.landing.subscribe") }}
                    </button>
                </div>
                <hr>
            </div>
        </div>
        <div class="auth-form mt-2" v-if="payment && paymentUrl!=null">
            <div class="w-100 text-center">
                <iframe :src="paymentUrl" width="550" height="600" frameborder="0" scrolling="auto"></iframe>
            </div>
        </div>
    </b-modal>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import vue from 'vue';
    export default {
        name:"subModal",
        data(){
            return {
                payment:false,
                paymentUrl:null,
                plans:[],
                login:{
                    name:"",
                    email:"",
                    password:"",
                    rePassword:""
                }
            }
        },
        methods:{
            subPlan(p){
                axios.post(apiDomain+'/oyu/payment/sub',{planId:p.planId},
                {headers:getHeader()})
                .then((response)=>{
                    if(response.data.result=='subscribed'){
                        alert(vue.i18n.translate("oyu.landing.activeSub"));
                        return;
                    }else{
                        this.payment = true ;
                        this.paymentUrl=response.data.result;
                    }        
                })
                .catch(error => {
                    alert(error.message);
                });
            },
            subPlans(){
                axios.post(apiDomain+'/oyu/payment/plan',{},
                {headers:getHeader()})
                .then((response)=>{
                   this.plans=response.data
                })
                .catch(error => {
                    alert(error.message);
                });
            },
            clearForm(){
                this.payment=false,
                this.paymentUrl=null,
                this.plans=[],
                this.login={
                    name:"",
                    email:"",
                    password:"",
                    rePassword:""
                }
            },
            closeModal(){
                this.$bvModal.hide('subModal');
            },
           
        },
        created(){
            this.subPlans();
        }
    }
</script>
<style scoped>
    .price-item{
        color: var(--Black, #000);
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .login-footer span{
        cursor: pointer;
        color: var(--black, #000);
        font-family: "Helvetica Neue LT W1G";
        font-size: 12px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .social-btn-container{
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        margin-right: 10px;
    }
    .login-btn{
        background: var(--black, #000);
        color: var(--white, #FFF);

        /* Text md/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .label-text{
        color: var(--gray-700, #344054);
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .login-title{
        color: var(--black, #000);
        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
    .login-sub-title{
        margin-top: 5px;
        color: var(--black, #000);

        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 15px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
</style>