<template>
 <b-modal id="regModal" :title="$t('lms.userForm.modalTitle')" size="lg" 
 @shown="keyBoard()"
 :header-bg-variant="bgVariant"
    header-text-variant="light"
    :body-bg-variant="bgVariant"
    body-text-variant="light"
    :footer-bg-variant="bgVariant"
    footer-text-variant="light"
    
 hide-footer hide-header>
    <div>
        <b-tabs content-class="mt-3" fill
            active-tab-class="text-primary"
            active-nav-item-class="font-weight-bold text-uppercase text-light bg-secondary"
        >
            <b-tab :title="$t('lms.login')" 
                
                active>
                <b-form v-on:submit.prevent="login" id="loginForm" autocomplete="off">
                    <b-form-row class="mb-3">
                        <b-col sm="auto" md="12">
                            <label  for="userEmail1">{{$t('lms.userForm.email')}}</label>
                            <input
                                style="z-index:10000000000"
                                autocomplete="off"
                                class="form-control bg-dark text-primary"
                                id="userEmail1"
                                v-model="loginForm.username"
                                type="email"
                                required
                            />
                        </b-col>
                        <b-col sm="auto" md="12">
                            <label  for="password">{{$t('lms.userForm.password')}}</label>
                            <input
                                class="form-control bg-dark text-primary"
                                id="password"
                                v-model="loginForm.password"
                                type="password"
                                required
                            />
                        </b-col>
                        <b-button block  class="mt-3" type="submit">Кіру</b-button> 
                    </b-form-row>
                    
                </b-form>    
            </b-tab>
            <b-tab :title="$t('lms.register')">
               
                <b-form v-on:submit.prevent="submitUser" id="regForm" autocomplete="off">
                    <b-form-row class="mb-3">
                        
                        <b-col sm="auto" md="12">
                            <label for="lastName">{{$t('lms.userForm.lastName')}}</label>
                            <b-form-input
                                id="lastName"
                                class="form-control bg-dark text-primary"
                                v-model="userForm.lastName"
                                required
                            ></b-form-input>
                        </b-col>
                        <b-col sm="auto" md="12">
                            <label  for="firstName">{{$t('lms.userForm.firstName')}}</label>
                            <b-form-input
                                id="firstName"
                                class="form-control bg-dark text-primary"
                                v-model="userForm.firstName"
                                required
                            ></b-form-input>
                        </b-col>
                        <b-col sm="auto" md="12">
                            <label  for="thirdName">{{$t('lms.userForm.thirdName')}}</label>
                            <b-form-input
                                id="thirdName"
                                class="form-control bg-dark text-primary"
                                v-model="userForm.thirdName"
                                required
                            ></b-form-input>
                        </b-col>
                        <b-col sm="auto" md="12">
                            <label  for="userEmail">{{$t('lms.userForm.email')}}</label>
                            <input
                                class="form-control bg-dark text-primary"
                                id="userEmail"
                                v-model="userForm.email"
                                type="email"
                                required
                            />
                        </b-col>
                        
                        <b-col sm="auto" md="12">
                            <label  for="password">{{$t('lms.userForm.password')}}</label>
                            <b-form-input
                                class="form-control bg-dark text-primary"
                                id="password"
                                v-model="userForm.password"
                                type="password"
                                required
                            ></b-form-input>
                        </b-col>
                        <b-col sm="auto" md="12">
                            <label  for="password">{{$t('lms.userForm.repeatPassword')}}</label>
                            <b-form-input
                                class="form-control bg-dark text-primary"
                                id="password"
                                v-model="userForm.password1"
                                type="password"
                                required
                            ></b-form-input>
                        </b-col>
                    </b-form-row>
                    <b-button type="submit" size="sm" variant="primary" class="mr-2">{{$t('lms.userForm.addUser')}}</b-button>
                    <b-button type="reset" size="sm" variant="danger">{{$t('lms.userForm.clear')}}</b-button>
                </b-form>    
               
            </b-tab>
            
        </b-tabs>
        
    </div>
     </b-modal>
</template>
<script>

import axios from 'axios';
import {apiDomain,getHeader,loginUrl} from "@/config/config";
import vue from 'vue';
const authUser = {};
export default {
    name:"ClientLogin",
    data(){
        return {
            bgVariant:"dark",
            userForm:{
                thirdName:"",
                firstName:"",
                lastName:"",
                email:"",       
                password:"",
                password1:""
            },
            loginForm:{
                username:"",
                password:""
            }
        }
    },
    methods: {
        keyBoard(){
            // const listener = function (e) {
            //     e.preventDefault();
            //     e.stopPropagation();
            // };
            
            // //alert("reseting . . .");
            // localStorage.clear() 
            // // $("textarea").on('keyup keydown keypress', function(e) {
            // //     e.stopPropagation();
            // // });
            

  

            // document.querySelector("#userEmail1").addEventListener("keydown", (event) => {
            //     console.log("sorry")
            //     return true ;
            // });
            // document.querySelector("#userEmail1").addEventListener("keyup", (event) => {
            //     //alert("dd")
            //     return true ;
            // });
        },
        login(){
            axios.post(loginUrl,{
            'username':this.loginForm.username,
            'password':this.loginForm.password
          }, {headers: getHeader()})
          .then((res)=>{
            if(res.status===200){
              authUser.access_token=res.data.access_token;
              authUser.refresh_token = res.data.refresh_token;
              window.localStorage.setItem('authUser',JSON.stringify(authUser));
              this.$router.push({name:'AfterAuth'});
            }
            if(res.status===401){
                this.$bvToast.toast(vue.i18n.translate("lms.userForm.wrongLogin"), {
                    title:vue.i18n.translate("system.errorTitle"),
                   
                    autoHideDelay: 5000
                })
            }
          })
          .catch(error => {
            let d =error.response.data;
            if(d.trim()=='blocked'){
                this.$bvToast.toast(vue.i18n.translate("lms.userForm.blockedUser"), {
                    title:vue.i18n.translate("system.errorTitle"),
                    variant:"danger",
                    autoHideDelay: 5000
                })
                return 
            }
            this.$bvToast.toast(vue.i18n.translate("lms.userForm.wrongLogin"), {
                title:vue.i18n.translate("system.errorTitle"),
                autoHideDelay: 5000
            })
          });
        },
        submitUser(evt){
            if(this.userForm.password!=this.userForm.password1){
                this.$bvToast.toast(vue.i18n.translate("lms.userForm.passwordsNotMatchError"),{
                    title:vue.i18n.translate("system.errorTitle"),
                   
                    autoHideDelay: 5000
                })
                return 
            }
            
            axios.post(apiDomain+'/landing/register',this.userForm,{headers:getHeader()})
            .then(response=>{
                let alertMsg = "Сәтті аяқталды";
                if(response.data==='dublicated'){
                    alertMsg='Пайдаланушының электрондық поштасы бұрын тіркелген.'
                }
                else{
                    this.userForm={
                        thirdName:"",
                        firstName:"",
                        lastName:"",
                        email:"",       
                        password:""
                    }
                }
                
                this.$bvToast.toast(alertMsg, {
                    title: 'Жетістік',
                    autoHideDelay: 5000
                })
            })
            .catch(error => {
                this.$bvToast.toast(vue.i18n.translate("lms.userForm.dublicatedEmail"), {
                    title:vue.i18n.translate("system.errorTitle"),
                   
                    autoHideDelay: 5000
                })
            }) 
        },
    }
}
</script>
<style scoped>
    .activeNav{
        color:red;
    }
        /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {

    -webkit-text-fill-color: #007bff;
    -webkit-box-shadow: 0 0 0px 1000px #343a40 inset;
    transition: background-color 5000s ease-in-out 0s;
    }
</style>
