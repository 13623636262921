<template>
  <router-view></router-view>
</template>
<script>
    
  export default {
    name: 'app'
  }
</script>

<style >
  @font-face {
    font-family: "Helvetica Neue LT W1G";
    src: local("Helvetica Neue LT W1G"),
    
    url(./fonts/helvetica/HelveticaNeueLTW1G-Th.otf);
  }

  body{
    font-family: 'Helvetica Neue LT W1G' !important;
  }
</style>


