<template>
    <div class="app">
      <b-navbar toggleable="xs" 
       class="custom-navbar  align-items-start m-0 p-0 sticky-top" :style="{backgroundColor: '#123456',  paddingTop: '0', paddingBottom: '0' }">
       
        <b-navbar-toggle target="nav-collapse" class="pt-3 border-0 pl-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.5 8.33333H2.5M17.5 5H2.5M17.5 11.6667H2.5M17.5 15H2.5" stroke="black" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </b-navbar-toggle>
        <div class="separator align-self-stretch"></div>
        <b-navbar-brand  class="pb-0">
          <img v-if="!isMob" @click="goHome()" src="@/assets/logo_container.png" >
          <img v-if="isMob" @click="goHome()" src="@/assets/logo_container_mobile.png" class="mr-4">
          
         
          <select v-if="isMob" @change="setLang" id="lang" v-model="selLang" class="m-0 p-0 border-0 outline-none customselect ml-1" style="font-size: 18px !important;">
              <option :value="locale" v-for="locale in $i18n.locales()" :key="'i81n-' + locale" >
                {{locale == 'kz' ? 'қазақ' : locale=='ru' ? 'русский' : locale=='en' ? 'english' : ''}}
              </option>
          </select>
          <b-icon v-if="isMob" icon="search" aria-hidden="true" class="p-0 m-0  ml-3 mr-1" style="cursor: pointer;" v-b-modal.searchModal></b-icon>

          <img v-if="isMob && !hasRole('CLIENT')" 
          src="@/assets/Vector.png" class="ml-1" style="cursor: pointer;" v-b-modal.loginModal>
          <img v-else
          src="@/assets/Vector.png" class="ml-1" style="cursor: pointer;" @click="goAccount()">

        </b-navbar-brand>


        <b-nav class="navbar-items ml-auto m-0 p-0  align-self-stretch d-none d-md-flex" >
          <div class="separator align-self-stretch"></div>
          <b-nav-item href="#" class="m-0 mt-0 p-0 text-center" v-b-modal.searchModal>
            <b-icon icon="search" aria-hidden="true" class="p-0 m-0 mt-3 mr-1"></b-icon>
            {{ $t("oyu.landing.search") }}
          </b-nav-item>
          <div class="separator align-self-stretch"></div>
          <b-nav-item href="#" class="m-0 mt-0 p-0 text-center pt-3" style="width: 80px;">
            <select @change="setLang" id="lang" v-model="selLang" class="m-0 p-0 border-0 outline-none customselect">
              <option :value="locale" v-for="locale in $i18n.locales()" :key="'i81n-' + locale" >
                {{locale == 'kz' ? 'қазақ' : locale=='ru' ? 'русский' : locale=='en' ? 'english' : ''}}
              </option>
            </select>
          </b-nav-item>
          <div class="separator align-self-stretch"></div>

          <b-nav-item href="javascript:void(0)"
           v-if="!hasRole('CLIENT')"
           class="m-0 mt-0 p-0 text-center pt-3" v-b-modal.loginModal>
            {{ $t("oyu.landing.login") }}
          </b-nav-item>
          <div v-if="!hasRole('CLIENT')" class="separator align-self-stretch"></div>
          <b-nav-item 
            v-if="!hasRole('CLIENT')"
            href="#" class="m-0 mt-0 p-0 pt-3 text-center" style="min-width: 130px;"  v-b-modal.regModal>
            {{ $t("oyu.landing.freeReg") }}
          </b-nav-item>
          <b-nav-item 
            v-if="hasRole('CLIENT')"
            href="/#/account" class="m-0 mt-0 p-0 pt-3 text-center" style="width: 90px;">
            {{ $t("oyu.landing.acc.title") }}
            
          </b-nav-item>
          <div class="separator align-self-stretch"></div>
        </b-nav>
      </b-navbar> 
      
      <LoginModal></LoginModal>
      <RegModal></RegModal>
      <ResetModal></ResetModal>
      <IntroModal></IntroModal>
      <SearchModal></SearchModal>
      <!-- @hidden="onSidebarHidden"
          @shown="onSidebarShown" -->
      <div class="p-0">
        <b-sidebar 
          v-model="sidebarOpen"
          visible 
          id="nav-collapse"
          width="200"
          body-class="custom-sidebar"
          no-close-on-route-change
          @hidden="hideSideBar()"
          :title="$t('oyu.landing.catTitle')">


          <div class="px-3 py-2" >
            <b-nav vertical class="navbar-items-sidebar">
              <b-nav-item href="javascript:void(0)" @click="productsByCat(c)" v-for="(c,ci) in cats" :key="ci">
                <span v-html="c.catIcon" class="custom-svg"></span>
                {{ $i18n.locale()=='kz' ?  c.catName : $i18n.locale()=='ru' ? c.catNameRu : $i18n.locale()=='en' ? c.catNameEn : "" }}
              </b-nav-item>


              <b-nav-item href="#" class="m-0 mt-0 p-0 mt-5" @click="closeSideBar()" v-b-modal.searchModal v-if="isMob">
                <b-icon icon="search" aria-hidden="true" class="p-0 m-0 mr-1"></b-icon>
                {{ $t("oyu.landing.search") }}
              </b-nav-item>
              <b-nav-item href="#" class="m-0 mt-0 p-0" v-if="isMob">
                <select @change="setLang" id="lang" v-model="selLang" class="m-0 p-0 border-0 outline-none customselect">
                  <option :value="locale" v-for="locale in $i18n.locales()" :key="'i81n-' + locale" >
                    {{locale == 'kz' ? 'қазақ' : locale=='ru' ? 'рус' : locale=='en' ? 'english' : ''}}
                  </option>
                </select>
              </b-nav-item>
              
              <b-nav-item href="javascript:void(0)" @click="closeSideBar()"
              v-if="!hasRole('CLIENT') && isMob"
              class="m-0 mt-0 p-0"  v-b-modal.loginModal>
                {{ $t("oyu.landing.login") }}
              </b-nav-item>
              <b-nav-item @click="closeSideBar()"
                v-if="!hasRole('CLIENT') && isMob"
                href="#" class="m-0 mt-0 p-0" v-b-modal.regModal>
                {{ $t("oyu.landing.freeReg") }}
              </b-nav-item>
              <b-nav-item @click="closeSideBar()"
                v-if="hasRole('CLIENT') && isMob"
                href="/#/account" class="m-0 mt-0 p-0">
                {{ $t("oyu.landing.acc.title") }}
                
              </b-nav-item>
              
            </b-nav>


          
           
          </div>
        
        </b-sidebar>
      </div>
    
      <b-container  fluid :class="[sidebarOpen ? 'opened' : 'closed']" style="padding-right:0 !important;"> 
          <router-view></router-view>

          <div class="d-block  my-5" style="height: 1px;background-color: #D0D5DD;"></div>
          <div class="block text-center">
            <img src="@/assets/logo_container.png" >
          </div>
          <div class="block text-center pt-3">
              <a href="javascript:void(0)" class="bottom-link" v-b-modal.introModal>{{ $t("oyu.landing.aboutApp") }}</a>
              <a 
              href="javascript:void(0)" 
              @click="fileRedirect('term')"
              class="bottom-link">{{ $t("oyu.landing.usageTerm") }}</a>

              <a href="javascript:void(0)"
              @click="fileRedirect('policy')"
              class="bottom-link">{{ $t("oyu.landing.policy") }}</a>

              <a href="javascript:void(0)" 
              @click="fileRedirect('proc')"
              class="bottom-link">{{ $t("oyu.landing.paymentProc") }}</a>

          </div>
          <div class="d-block mx-3 my-5" style="height: 1px;background-color: #D0D5DD;"></div>
          <div class="block text-center mb-5">
            <span class="copyright">
             {{$t("oyu.landing.copyright")}}
            </span>
          </div>
      </b-container>
   </div>
</template>

<script>
import AppHeader from '@/components/lmslanding/LandingHeader';
import LoginModal from '@/components/lmslanding/LoginModal';
import SearchModal from '@/components/lmslanding/SearchModal';
import RegModal from '@/components/lmslanding/RegModal';
import ResetModal from '@/components/lmslanding/ResetModal';
import IntroModal from '@/components/lmslanding/IntroModal';
import axios from 'axios';
import {apiDomain,getHeader} from "../config/config";
import {EventBus} from "@/EventBus.js"
import { isMobile } from 'mobile-device-detect';
import {mapActions,mapState} from 'vuex';
import vue from 'vue'

export default {
  name: 'Full',
  components: {
    AppHeader,
    LoginModal,
    RegModal,
    IntroModal,
    ResetModal,
    SearchModal
  },
  data(){
    return {
     
      cats:[],
      selLang:"kz",
      pdfs:{
        showTopMenu:false,
        policy:{
          kz:"",
          ru:"",
          en:"https://drive.google.com/file/d/1HSJy26ID3vUxi6z3yurksQkQABwfO3Cv/preview"
        }
      },
      sidebarOpen: true
    }
  },

  methods:{
    hideSideBar(){
      //if(!this.sidebarOpen) this.sidebarOpen=true;
    },
    goAccount(){
      
      window.location.href="/#/account";
    },
    goHome(){
      
      window.location.href="/#/intro";
    },
    fileRedirect(path){
      window.location.href="/#/intro";
      window.location.href="/#/pdfview/"+path;
    },
    hasRole(roleName){
      if(this.loginedUser){
        if(this.loginedUser.userRoles){
          let roleNames = this.loginedUser.userRoles;
          if(roleNames.length>0){
            //alert(JSON.stringify(roleNames));
            for(let r=0;r<roleNames.length;r++){
              
              if(roleNames[r].name===roleName){
                return true;
              }
            }
          }
          else{
            return false;
          }
        }
      }else{
        return false;
      }
      return false;
    },
    logOut : function(){
      window.localStorage.removeItem("authUser");
      window.localStorage.clear();
      this.logoutSystem()
      this.$router.push({name:'Intro'});
      
      // axios.get(apiDomain+'/admin/logout',{headers:getHeader()})
      // .then(()=>{
      //     window.localStorage.removeItem("authUser");
      //     window.localStorage.clear();
      //     this.$router.push({name:'Login'});
      // })
      // .catch(error => {
      //     alert(error.message);    
      // })
       
    },
    ...mapActions([
        'logoutSystem',
    ]),
    closeSideBar(){
      this.sidebarOpen=false;
    },
    setLang(){
      if (vue.i18n.locale !== this.selLang) {
        vue.i18n.set(this.selLang);
        this.sidebarOpen=false;
      }
    },
    productsByCat(obj){
      if(isMobile){
        this.sidebarOpen=false;
      }else{
        this.sidebarOpen=true;
      }
      
     
      let rName = this.$route.name;
      
      
      let catId = Number(obj.catId);
      let catName = vue.i18n.locale()=='kz' ? obj.catName :  vue.i18n.locale()=='ru' ? obj.catNameRu 
      :  vue.i18n.locale()=='en' ? obj.catNameEn : "";

      EventBus.$emit("productsByCat",catId,catName);
      if(rName!='Intro' && rName!='ProductList'){
        window.location.href = `/#/productlist/${catId}/${catName}`
      }
      //if(isMobile)
      
    },
    onSidebarShown() {
      console.log('Sidebar is shown');
      this.sidebarOpen=true;
    },
    onSidebarHidden() {
      console.log('Sidebar is hidden');
      this.sidebarOpen=false;
    },
    homeCats(){
      axios.post(apiDomain+'/oyu/mobile/homecats',
      {},{headers:getHeader()})
      .then(response=>{ 
          response.data.forEach(c=>{
            let svg = c.catIcon;
            const height = '24';
            const width = '24';
            svg = svg.replace('<svg ', `<svg height="${height}" width="${width}" `);
            c.catIcon=svg;
            this.cats.push(c);
          })
      })
      .catch((err) => {
        console.log(err);
      }) 
    }
  },
  
  computed: {
    sidebarOpen1(){
      return  isMobile ?  false : true;
    },
    isMob(){
      return isMobile
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
    },
    ...mapState([
        
        'loginedUser'
    ])
  },
  mounted(){
    
    if(isMobile){
      this.sidebarOpen=false;
    }else{
      this.sidebarOpen=true;
    }
    this.homeCats();
  }
  
}
</script>
<style scoped>
  

  .customselect:focus {
    outline: none;
    /* You can add additional styling as needed */
  }




  .custom-svg svg {
      width: 24px !important;
      width: 24px !important;
  }
  ::v-deep .b-sidebar {
    background-color: #fff !important;
  }
  .navbar-items .nav-link{
    color: var(--black, #000);
    margin: 0;
    padding: 0;
    /* Text sm/Regular Underlined */
    color: var(--black, #000);
    /* Text sm/Regular */
    font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    
  }
  .navbar-items-sidebar .nav-link{
    color: var(--black, #000);
   
    /* Text sm/Regular Underlined */
    color: var(--black, #000);
    /* Text sm/Regular */
    font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    
  }
  .custom-navbar {
    background-color: #fff !important;
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD; 
  }

  .separator {
      border-left: 1px solid #D0D5DD;
    
      margin-left: 16px;
      margin-right: 16px;
  }
  .align-items-start {
    justify-content: flex-start !important;
  }
  @media (min-width: 768px) {
  ::v-deep #nav-collapse {
    margin-top: 80px; /* Apply margin only on desktop screens (width >= 768px) */
  }
  
}
  .closed{
    padding-left: 0;
   
  }
  .opened{
    padding-left: 320px;
    
  }
  .copyright{
    color: var(--gray-400, #98A2B3);
    text-align: center;

    /* Text sm/Regular */
    font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }
  .bottom-link{
    color: var(--gray-500, #667085);
    /* Text sm/Regular */
    font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    margin-right: 30px;
  }
</style>
