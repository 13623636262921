<template>
     <!-- begining of the home products -->
    <b-row class="p-0 m-0">
        <b-col lg="12">
            <div class="d-block py-4 mb-4">
                <span class="float-left ornament-title">
                {{catName.length==0 ? $t("oyu.landing.ornaments") : catName}}
                </span>
            </div>
            <div class="d-block mb-5" style="height: 1px;background-color: #D0D5DD;"></div>
        </b-col> 

        <b-col lg="2" md="4" cols="6"
            v-for="(p,pi) in products" :key="pi" 
            class="pb-5" style="min-height: 300px;"  :style="[isBorderRequired(pi) ? 'border-right:1px solid #D0D5DD' : '']">
            
           <div class="d-block text-right mb-5" @click="likeProduct(p)">
                <img src="@/assets/love.png" width="20" height="18" v-if="p.isLiked==0">
                <img src="@/assets/loved.png" width="20" height="18" v-if="p.isLiked>0">
            </div>

            <div class="d-block text-center" @click="viewProduct(p)">
                <img style="max-width:100%;height: 80px;" :src="'https://www.oyuapp.com/api/img?imagePath='+p.image">
            </div>
            <div class="d-block text-center pt-3 home-p" @click="viewProduct(p)">
                {{ 
                    $i18n.locale()=='kz' ? p.productName
                    : $i18n.locale()=='ru' ? p.productNameRu 
                    : $i18n.locale()=='en' ? p.productNameEn : "" 
                }}
            </div>
        </b-col>

    </b-row>
    <!-- end of the home products -->
</template>
<script>
  import axios from 'axios';
  import {apiDomain,getHeader} from "@/config/config"; 
  import {EventBus} from "@/EventBus.js"
  import OyuService from "@/services/oyu_service.js";
  import { isMobile } from 'mobile-device-detect';
  export default {
    data() {
      return {
        products:[],
        catName:"",
        catId:0
      }
    },
    methods: {
      viewProduct(p){
        window.location.href = "/#/view/"+p.productId;
      },
      async likeProduct(obj){
          const service = new OyuService(apiDomain,getHeader());
          try {
              const data = await service.likeProduct({"productId":obj.productId});
              if(data.isSuccess){ 
                  if(obj.isLiked>0){
                      obj.isLiked=0;
                  }else{
                      obj.isLiked=1;
                  }
              }
          } catch (error) {
              alert(error.message);
          }
      }, 
      isBorderRequired(index) {
          const itemsInRow = 6;
          return index != this.products.length - 1 && (index + 1) % itemsInRow != 0;
      },
     
      homeProducts(catId=0){
        axios.post(apiDomain+'/oyu/mobile/pagedproducts',
        {
          "pageNum":1,
          "catId":Number(catId),
          "itemsPerPage":800
        },{headers:getHeader()})
        .then(response=>{ 
          
           this.products=response.data;
           
        })
        .catch(() => {
            this.calculating=false;
        }) 
      }
    },
    created(){
      let catId = this.$route.params.catId;
      let catName = this.$route.params.catName;
      if(catName!='default')
        this.catName=catName
      this.homeProducts(catId);  
      
    },
    watch: {
        $route(to, from) {
          let catId = this.$route.params.catId;
          let catName = this.$route.params.catName;
          if(catName!='default')
            this.catName=catName
          this.homeProducts(catId);         
        }
    },
    mounted(){
      let t = this;
      EventBus.$on("productsByCat",(d,catName)=>{
         t.catName = catName;
         t.homeProducts(Number(d));
      });
    }
};

</script>
<style scoped>
   .home-p{
    font-family: "Helvetica Neue LT W1G";
    color: var(--Black, #000);
    /* Text md/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }
  .ornament-title{
    color: var(--black, #000);
    /* Text lg/Semibold */
    font-family: Helvetica Neue LT W1G;
    font-size: 18px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }
  .all-link{
    color: var(--gray-600, #475467);
    text-align: right;

    /* Text sm/Regular */
    font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }

</style>
  


