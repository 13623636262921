import axios from "axios";
import store from "@/store.js"
import vue from 'vue';
const productLikeUrl="/oyu/product/likeproduct"
const downloadUrl="/oyu/product/download"
class OyuService {
    constructor(baseURL,headers) {
        this.api = axios.create({
            baseURL,
            headers: headers
        });
    }
    logoutSystem(){
        window.localStorage.removeItem("authUser");
        window.localStorage.clear();
        store.dispatch('logoutSystem');
        window.location.href = "/#/intro";         
    }
    async likeProduct(payload) {
        try {
            const response = await this.api.post(productLikeUrl, payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    downloadProductJpg(payload) {
        this.api.post(downloadUrl,payload).then(
        (res)=>{
            const svgData = res.data;
            const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
            const svgUrl = URL.createObjectURL(svgBlob);

            const svgImage = new Image();
            svgImage.onload = () => {
                const canvas = document.createElement("canvas");
                // Increase the canvas resolution for better quality
                const width = svgImage.width * 7; // You can adjust the scaling factor
                const height = svgImage.height * 7; // You can adjust the scaling factor
                canvas.width = width;
                canvas.height = height;
                const canvasCtx = canvas.getContext("2d");
              
                // You can set rendering options for better quality
                canvasCtx.imageSmoothingEnabled = true;
                canvasCtx.imageSmoothingQuality = "high";              
                canvasCtx.drawImage(svgImage, 0, 0, width, height); // Draw the SVG scaled
                canvas.toBlob((blob) => {
                  const pngUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  downloadLink.href = pngUrl;
                  downloadLink.download = "image.jpg";
                  downloadLink.click();
                  URL.revokeObjectURL(pngUrl);
                }, "image/jpg");
            };
            svgImage.src = svgUrl;
        }      
        ).catch((error)=>{
            if(error.response.status==401){
                alert("zaival");
                alert(vue.i18n.translate("oyu.landing.operation"));
                return ;
            }
        });
    }
    downloadProductPng(payload) {
        this.api.post(downloadUrl,payload).then(
        (res)=>{
            const svgData = res.data;
            const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
            const svgUrl = URL.createObjectURL(svgBlob);

            const svgImage = new Image();
            svgImage.onload = () => {
                const canvas = document.createElement("canvas");
                // Increase the canvas resolution for better quality
                const width = svgImage.width * 7; // You can adjust the scaling factor
                const height = svgImage.height * 7; // You can adjust the scaling factor
                canvas.width = width;
                canvas.height = height;
                const canvasCtx = canvas.getContext("2d");
              
                // You can set rendering options for better quality
                canvasCtx.imageSmoothingEnabled = true;
                canvasCtx.imageSmoothingQuality = "high";              
                canvasCtx.drawImage(svgImage, 0, 0, width, height); // Draw the SVG scaled
                canvas.toBlob((blob) => {
                  const pngUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  downloadLink.href = pngUrl;
                  downloadLink.download = "image.png";
                  downloadLink.click();
                  URL.revokeObjectURL(pngUrl);
                }, "image/png");
            };
            svgImage.src = svgUrl;
        }      
        ).catch((error)=>{
            if(error.response.status==401){
                alert("zaival");
                alert(vue.i18n.translate("oyu.landing.operation"));
                return ;
            }
        });
    }
    downloadProduct(payload) {
        this.api.post(downloadUrl,payload).then(
        (res)=>{
            // Create a Blob URL from the received Blob data
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'downloaded-file.svg'; // Set the desired file name
            link.click();
            window.URL.revokeObjectURL(blobUrl);
        }      
        ).catch((error)=>{
        if(error.response.status==401){
            
            alert(vue.i18n.translate("oyu.landing.operation"));
            return ;
        }
        });
  
         
       
      }
    // async fetchData(endpoint) {
    //     try {
    //         const response = await this.api.get(endpoint);
    //         return response.data;
    //     } catch (error) {
    //         throw error;
    //     }
    // }
}
export default OyuService;