<template>
  <div>
   
   
  </div>  
</template>

<script>
import {mapActions,mapState} from 'vuex';
import axios from 'axios';
import {apiDomain,getHeader} from "@/config/config";
import ClientLogin from '@/components/lmslanding/ClientLogin';
import {EventBus} from '@/EventBus.js';
export default {
  name: 'LandingHeader',
  components:{
    ClientLogin
  },
  data(){
    return {
      cCount:0,
      statisticData:null,
      showOrderLoanModal : false,
      salaryInfo:[],
    }
  },

  methods:{
    assignedCourse(){
      window.location.href = "/#/assigned";  
    },
    courseCount(){
      axios.post(apiDomain+'/landing/coursecount',{},{headers:getHeader()})
      .then((res)=>{
          this.cCount = res.data;
      })
      .catch(error => {
          alert(error.message);    
      })
    },
    showLoanModal(){
      this.showOrderLoanModal=true;
      this.$bvModal.show("orderLoanModal");
    },
    
    
    logOut : function(){
      //alert("Server taliin token arilgahaa martavaa Bek chuhal shvv!!! ");
      EventBus.$emit("logout")
      
      window.localStorage.removeItem("authUser");
      window.localStorage.removeItem("loginedUser");
      window.localStorage.clear();
      this.logoutSystem();
      this.$router.push({name:'Intro'});
      
      // axios.get(apiDomain+'/admin/logout',{headers:getHeader()})
      // .then(()=>{
      //     window.localStorage.removeItem("authUser");
      //     window.localStorage.removeItem("loginedUser");
      //     window.localStorage.clear();
      //     this.$router.push({name:'Intro'});
      // })
      // .catch(error => {
      //     alert(error.message);    
      // })

      
       
    },
    ...mapActions([
       'logoutSystem'
    ]),
   
  
    hasRole(roleName){
      if(this.loginedUser){
        if(this.loginedUser.userRoles){
          let roleNames = this.loginedUser.userRoles;
          if(roleNames.length>0){
            //alert(JSON.stringify(roleNames));
            for(let r=0;r<roleNames.length;r++){
              
              if(roleNames[r].name===roleName){
                return true;
              }
            }
          }
          else{
            return false;
          }
        }
        return false;
      }
      return false;
      
      
      
    }
  },
  computed:{
    ...mapState([
        'loginedUser'
    ]),
  }
}
</script>
<style scoped>
  .pulse {
  
  display: inline-block;
  margin-top: 5px;

  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 1s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
