<template>
     
    <main class="auth auth-floated" style="width:50%;margin:auto;">
      
      <form class="auth-form" v-on:submit.prevent="handleLoginFormSubmit">
        <div class="mb-4">
          <div class="mb-3">
            <img class="rounded" src="assets/apple-touch-icon.png" alt="" height="72">
          </div>
          <h1 class="h3"> Кіру </h1>
        </div>
        
        <div class="form-group mb-4">
          <label class="d-block text-left" for="inputUser">Тұтнушы аты</label> 
          <input 
            type="text" 
            id="inputUser" 
            class="form-control form-control-lg" 
            required="" 
            v-model="login.username"
            autofocus="">
        </div>
        <div class="form-group mb-4">
          <label class="d-block text-left" for="inputPassword">Құпия сөз</label>
          <input 
            type="password" 
            id="inputPassword" 
            class="form-control form-control-lg"
            required=""
            v-model="login.password"
            >
        </div>
        <div class="form-group mb-4">
          <button class="btn btn-lg btn-primary btn-block" type="submit">Кіру</button>
        </div>
      </form>
    </main>
</template>
<script>
  import axios from 'axios';
  import {getHeader,loginUrl} from "../config/config";
 
  const authUser = {};
  export default {
       name : "Login",
       data(){
        return {
          login:{
            username:'',    
            password:''
          }
        }
      },
      methods:{
        handleLoginFormSubmit(){
          //alert(JSON.stringify(header));
          axios.post(loginUrl,{
            'username':this.login.username,
            'password':this.login.password
          }, {headers: getHeader()})
          .then((res)=>{
            if(res.status===200){
              authUser.access_token=res.data.access_token;
              authUser.refresh_token = res.data.refresh_token;
              window.localStorage.setItem('authUser',JSON.stringify(authUser));
              this.$router.push({name:'AfterAuth'});
            }
            if(res.status===401){
              alert("Сіз енгізген ақпарат дұрыс емес.");
            }
          })
          .catch(error => {
              alert("Сіз енгізген ақпарат дұрыс емес.");

          });
        }
        
      }        
  }
</script>
<style scoped>

</style>