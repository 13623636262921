//export const  apiDomain = "http://localhost:8081";
export const  apiDomain = "https://www.oiuapp.com/api";

export const  backendDomain = "http://16.171.26.14:8080";
export const  webSocket = "ws://16.171.26.14:8080";
//export const apiDomain="";

export const loginUrl = apiDomain+"/syslogin";
export const getHeader = function(){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  if(tokenData){
    const headers= {
        'Accept':'application/json',
        'Authorization':'Bearer ' + tokenData.access_token
    }
    return headers;
  }
  else {
      return null;
  }
}

export const getXlsHeader = function(){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  const headers= {
    'Accept': 'application/vnd.openxmlformats-officedocument'
           + '.spreadsheetml.sheet',
    'Authorization':'Bearer ' + tokenData.access_token
  }
  return headers;
}
export const getMultipartHeader = function (){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  
  const headers= {
    'Content-Type': 'multipart/form-data',
    'Authorization':'Bearer ' + tokenData.access_token
  }
  return headers;

}

export const testFunction= function(){
  //alert("");
  //let u go main test 
  //console.log("ene bol busgui chini");  

}
