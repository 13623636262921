<template>
    <b-modal id="introModal" hide-footer hide-header>
        <div class="w-100 text-right" >
            <svg 
            style="cursor: pointer;" @click="closeModal"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="d-block text-center login-title">
            {{ $t("oyu.landing.aboutApp") }}
        </div>
        <div class="d-block text-left login-sub-title p-2">
            <span v-html="$t('oyu.landing.appIntro')"></span>
        </div>
        
    </b-modal>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import vue from 'vue';
    export default {
        name:"IntroModal",
        data(){
            return {
               
            }
        },
        methods:{
            closeModal(){
                this.$bvModal.hide('introModal');
            },
        },
    }
</script>
<style scoped>
    .login-footer span{
        cursor: pointer;
        color: var(--black, #000);
        font-family: "Helvetica Neue LT W1G";
        font-size: 12px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .social-btn-container{
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        margin-right: 10px;
    }
    .login-btn{
        background: var(--black, #000);
        color: var(--white, #FFF);

        /* Text md/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .label-text{
        color: var(--gray-700, #344054);
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .login-title{
        color: var(--black, #000);
        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 26px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
    .login-sub-title{
        margin-top: 5px;
        color: var(--black, #000);

        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 15px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: 1.6;
    }
</style>