export default {
	logoName:'OYUAPP',
	comboChooseText:'--Таңдау--',
	save :"Сақтау",
	reset:"Қалпына келтіру",
	logOut:'Жүйеден шығу',
	oyu:{
		landing:{
			noSub:"сізде белсенді жазылым жоқ.",
			activeSub:"Сізде белсенді жазылым бар.",
			passRecover:"Құпия сөзді өзгерту",
			changePassDesc:"Электрондық пошта арқылы құпия сөзді қалпына келтіру",
			sendingCode:"жалғастыру",
			recoverCode:"Электрондық поштаңызға жіберілген 4 санды код",
			newPass:"Жаңа құпия сөз",
			newPassRe:"Құпия сөзді қайталаңыз",
			signCount:"Құпия сөздің ұзындығы 4 белгіден көп болуы керек",
			passChanged:"Құпия сөз сәтті өзгертілді",
			wrongCode:"Қате код",
			notSame:"Құпия сөз сәйкес келмейді",
			errorTitle:"Қате",
			changePassword:"құпия сөзді өзгерту",
			moreSign:"құпия сөзде 4 белгіден көп болуы керек",
			noUser:"Электрондық пошта тіркелмеген",

			catTitle:"Санаттар",
			passChar:"Құпия сөзде 4 белгіден көп болуы керек",
			notSame:"Құпия сөз сәйкес келмейді",
			wrongLog:"Электрондық пошта немесе құпия сөз дұрыс емес",
			download:"Жүктеп алу",
			copyright:"© 2023 OYU App. Барлық құқықтар қорғалған.",
			register:"тіркелу",
			search:"іздеу",
			login:"кіру",
			acc:{
				title:"аккаунт",
				choosen:"таңдалған оюлар",
				logout:"аккаунттан шығу",
				private:"жеке ақпарат",
				sub:"сіздің жазылымыңыз",
				upate:"өңдеу",
				manageSub:"жазылымды басқару",
				download:"жүктеп алу"
			},
			chooseProduct: "Өз оюыңды<br>таңда!",
			chooseP: "Таңдау",
			slideDesc1:"13 санат бойынша 700-ден астам ою жинағын алу мүмкіндігі",
			subscribe : "Жазылу",
			loginTitle: "OYU қолданбасына қош келдіңіз!",
			loginSubTitle: "кіру әдісін таңдаңыз",
			email:"электрондық пошта",
			signName: "есіміңіз",
			password:"құпия сөз",
			rePassword:"құпия сөзді қайталаңыз",
			login:"кіру",
			freeReg:"тегін тіркелу",
			signUp: "тіркелу",
			forgotPassword:"құпия сөзді ұмыттыңыз ба?",
			regFree:"тегін тіркелу",
			registered:"Электрондық пошта бұрыннан тіркелген.",
			regSuccess: "Сіз сәтті тіркелдіңіз.",
			operation: "Бұл әрекетті орындау үшін жүйеге кіруіңіз керек.",
			firstQuestion: "Төмендегі әрекеттердің қайсысы сізге ерекше сезім сыйлар еді ?",
			privateOrnament: "сіздің жеке оюыңыз",
			ornaments: "оюлар",
			allCats: "барлығы",
			fileType:"файл түрі",
			download:"Жүктеп алу",
			backOrnaments:"оюға оралу",

			bookText:"700-ден астам ою жинағы",
			authorText:"“Қазақ оюларына арналған мұндай ауқымдағы каталог бұған дейін болған емес”.",
			authorName:"Ерлан Карин",
			buyText:"сатып алу",
			buy1:"Meloman",
			buy2:"Marwin",
			downloadText1:"Сонымен қатар, қосымша бизнес үшін де, жеке қызығушылықтарға қолдану үшін де қолжетімді",
			downloadText2:"қосымшаның көмегімен арнайы сұрақтарға жауап беру арқылы өз оюыңызды таңдай аласыз.",

			aboutApp: "сайт туралы",
			usageTerm:"пайдаланушы келісімі",
			policy:"құпиялылық саясаты",
			paymentProc:"төлем тәртібі",
			appIntro: "OYU app  - қазақ мәдениетінің маңызды бөлігі оюлар туралы толыққанды  визуалды ақпарат беретін және оның қолданыс аясын дұрыс ұйымдастырып, дамытуға мүмкіндік беретін веб-сайт.<br><br> Веб-сайтта 13 санатқа жинақталған 700-ден астам ою түрлері бар. Веб-сайт кәсіби дизайнерлер қауымдастығы мен қарапайым тұтынушыларға арналған.",
			

		},
		qGroup:{
			qGroup:"Сұрақтар тобы",
			groupNameKz:"Топтың аты kz",
			groupNameRu:"Топтың аты Ru",
			groupNameEn:"Топтың аты En",
		},
		question:{
			question:"Сұрақ",
			questionRu:"Сұрақ Ru",
			questionEn:"Сұрақ En",
			answers:"Жауаптар",
			createQuestion:"Сұрақ құру"
		},
		oyuCat:{
			catName:"Санат",
			catNameRu:"Санат ru",
			catNameEn:"Санат en",			
			catIcon:"ICON",			
			productCount:"Оюулар саны",	
			createOyuCat:"Санат құру"		
		},
		oyuProduct:{
			productName:"Аты Kz",
			productNameRu:"Аты Ru",
			productNameEn:"Аты En",
			image:"Сурет",
			catNames:"Санаттар",
			description:"Сипаттама",
			createOyuProduct:"Жазба қосу",
			addToAnswer:"Жауапқа Оюулар қосу"
		},
		interval:{
			intervalName:"Интервал атауы",
			beginPoint:"Басталуы",
			endPoint:"Аяқталу",
			addProduct:"Элементті қосу",
			isActive:"Белсенді",
			productCount:"Оюу саны",
			addProduct:"Оюу қосу"
		}
	},
	system:{
		errorTitle:'Қате',
		successTitle:'www.oyuapp.com',
		successMsg:"Сәтті аяқталды",
		dublicationMsg:'Жазба қайталанған !!!',
		newRecord:"Жаңа жазба",
		confirmMsg:'Сіз сенімдісіз бе ?',
		selectAll:'Барлығын таңдаңыз',
		unSelectAll:'Барлығын алып тастау',
		status:'Статус',
		beginDate:"Басталу күні",
		endDate:"Аяқталу күні",
		search:"Іздеу",
		loading:"Жүктелуде",
		add:'қосу',
		delete:'жою',
		update:'жаңарту',
		failMsg:"Белгісіз қате анықталды. Қайталап көріңіз.",
		delete:"Жою",
		update:"Жаңарту",
		actions:"Әрекеттер"
	},

	mainMenu: {
		
		currentLang:'КАЗ',
		users: 'Қолданушылар',
		courseAndModule :"Курс && Модуль",
		lecture:"Лекция",
		lab:"Лаборатория",
		server :"Серверді басқару",
		test:"Тест",
		video:"Видео"
	},
	lMenu:{
		courses:"курстар",
		freeCourses:"тегін курстар",
		start:"бастау"
	},
	lms:{
		machineTurnOnError:"an error occured when turning on machines"
	},
	
	deliveryList:{
		deliveryListTitle:"Тапсырыстар тізімі",
		newDelivery: "Жаңа",
		inProgress:"Орындалуда",
		done:"Дайын",
		deepSearch:"Терең іздеу",
		cassReport:"Касс есебі",
		byOrderNumber:"Тапсырыс нөмірі бойынша",
		byNewOrder:"Жаңа тапсырыс бойынша",
		

	},
	orderDate:"Тапсырыс күні",
	orderNumber:"Тапсырыс нөмірі",
	userInfo:"Клиент туралы ақпарат",
	totalProductPrice:"Өнімнің жалпы бағасы",
	takenCostByCash:"Қолма-қол ақшамен",
	takenCostByCard:"Карта арқылы",
	deliveryLoan:"Тапсырыс несиесі",
	showDetails:"Мәліметтерді көрсету",
	updateOrder:"Тапсырысты жаңарту",
	deleteOrder:"Тапсырысты жою",
	noSlicers:"Кесушiлер тандамаган",
	catName:"Өнім санаты",
	colorName:"Өнімнің түсі",
	measureName:"Өлшемі",
	productCount:"Өнім саны",
	doneCount:"Аяқталған саны",
	leftCount:"Аяқталмаған саны",
	productPrice:'Өнімнің бағасы',
	totalPrice:"Жалпы бағасы",
	wareHouseName:"Қойма",
	
	listSlicers:"Кесушілер",
	completed:"Аяқталды",
	unCompleted:"Аяқталмаған",
	orderActions:"Әрекеттер",
	teacherModule:{
		myDocuments : 'Менің құжаттарым',
		newDocument : 'Жаңа құжатты толтыру'
	},
	userPage :{
		userList:'Қолданушылар тізімі'
	},
	wrongFile:"Жүктелуі тиіс файлдың атын ауыстыруыңызды сұраймыз. Файл атауында нүкте немесе с.с. арнайы символдар болмауы тиіс!!!",
	
	lms:{
		login:"Жүйеге кіру",
		register:"Жүйеге тіркелу",
		userList:{
			systemUsers:'Жүйе пайдаланушылары',
			firstName:'Аты',
			lastName:'Тек',
			userName:'Логин аты',
			phoneNumber:'Телефон нөмірі',
			role :'Жүйедегі рөлі',
			blocking:"Блоктау",
			unblocking:"Блоктан шығару",
			enroll:"Тіркелу"
		},
		
		userForm:{
			modalTitle:"Пайдаланушы ақпараты",
			userType:"Пайдаланушы түрі",
			thirdName:"Әкесінің аты",
			lastName:"Тек",
			firstName:"Аты",
			phoneNumber:"Телефон нөмірі",
			email:"Электрондық пошта",
			password:"Құпия сөз",
			repeatPassword:"Құпия сөзді қайталаңыз",
			addUser:"Пайдаланушы қосу",
			clear:"Тазалау",
			roleConfirm:"Пайдаланушы түрін таңдау керек",
			passwordsNotMatchError:"Сіз енгізген құпия сөздер бірдей емес!!!",
			dublicatedEmail:"Электрондық пошта бұрыннан тіркелген!!!",
			wrongLogin:"Электрондық пошта немесе құпия сөз дұрыс емес.",
			blockedUser:"Сіз блокталған пайдаланушысыз. Жүйе әкімшісіне хабарласыңыз"
		},
		
		
		courseList:{
			mainTitle:"Курс пен модульді басқару",
			courseList:"Курс тізімі",
			courseName:"Аты",
			moduleCount:"Модульдер",
			courseCount:"Курстар",
			studentCount:"Студенттер",
			status:"Күй",
			delete:"Жою",
			active:"Белсенді",
			inActive:"Белсенді емес",
			newCourse:"Жаңа курс [+]",
			moduleName:"Модуль",
			lectureCount:"Лекция",
			labCount:"Лабротория",

			//new things
			yes:"Иә",
			no:"Жоқ",
			isFree:"Курс тегін бе, жоқ па",
			description:"Сипаттама",
			delete:"жою",
			introduction:"Курсқа кіріспе",
			isDefault:"Негізгі,негізгі емес",
			buildIntro:"Курстың кіріспесін құрастыру"

			
		},
		courseForm:{
			chooseFile:"Файлды таңдаңыз ...",
			dropFile:"Файлды осы жерге тастаңыз..."
		},

		moduleList:{
			moduleFiles:"Модуль бөліктері",
			create:"Модуль бөліктерін құрастыру",
			createModule:"Модуль жасау"
		},
		moduleForm:{
			modalTitle:"Курсты құру және жаңарту",
			chooseCourses:"Модульді қамтитын курстарды таңдаңыз"
		},
		serverList:{
			mainTitle:"Лабротория құруға арналған серверлер",
			serverName:"Сервер атауы",
			ipAddress:"Сервер IP",
			description:"Сипаттама",
			imagePath:"Логотип",
			createServer:"Сервер құру",
			userName:"Сервер пайдаланушы аты",
			password:"Сервер құпия сөзі",
			protocol:"Сервер протоколы",
			hasPublicIp:"Серверде сыртқы IP бар ма ?",
			ami:"AMI",
			ipMask:"IPMASK",
			instanceType:"Instance type"
		},
		labList:{
			mainTitle:"Әр модуль үшін лаброториялар",
			chooseServers:"Лабротория үшін пайдаланылатын серверлерді таңдаңыз",
			labName:"Лабротория атауы",
			labText:"Лабротория сипаттама",
			labEmbedded:"Лабротория бейне сабақ",
			chooseScorm:"Лаброторияға арналған SCORM файлы",
			labFiles:"Қосылған бөліктер",
			createLab:"Лабротория құру",
			servers:"Серверлер",

			isLecture:"Лекция ма, жоқ па?",
			lectureList:"Лекциялар",
			createLecture:"Дәріс құру",
			lectureName:"Лекция атауы",
			lectureText:"Лекция сипаттама",
			addToModule:"Модульге қосу"

		},
		videoList:{
			mainTitle:"Бейне тізімі",
			name:"Аты",
			description:"Сипаттама",
			showVideo:"Көру",
			videoPath:"Бейне коды",
			bottomText:"Бейнероликтің кіріспе мәтіні",
			createVideo:"Жаңа бейне жасау"
		},
		catList:{
			createCat:"Санат жасау",
			catName : "Санат атауы"
		},
		add:"Жазба қосу",
		clear:"Тазалау",
		chooseImage:"Суретті таңдаңыз.",
		detailSuccess:"Компонент сәтті жасалды.",
		image:"Фото компонент",
		scorm:"Scorm компоненті",
		video:"Бейне компоненті",
		text:"Мәтіндік компонент",
		command:"Code, command",
		detailComps:"Құрамдас бөліктер",
		courseCategory:"Курс санаты [+]",
		chooseCats:"Санаттарды таңдаңыз",
		isDefault:"Лекция негізгі бола ма?",
		vps:{
			manageVps:"VPS басқару",
			vpsName:"VPS атауы",
			manageVps:"Server vpses",
			chooseVpses:"VPS таңдаңыз"
		},
		myCourses:"Менің курстарым",
		mustLoginError:"Курсты көру үшін жүйеге кіру керек!!!",
		selectCourses:"Курстарды таңдау."
		
	}
};