<template>
    <div class="w-100 position-relative banner-container  overflow-hidden" :style="divStyle">
        <img v-if="!mobile" class="position-absolute" style="right:0px;max-height: 500px;"
         src="@/assets/subscribe_banner.png">

         <img v-if="mobile" class="position-absolute" style="right:0px;bottom: 0; max-height: 500px;"
         src="@/assets/mobile_banner.png">

        <div class="position-absolute" v-if="!mobile"
            style="bottom:13%;left:40px;">
            <span class="chooseP">OYU APP Pro</span>
            <div class="pro-desc  pt-2 mb-4" v-html="$t('oyu.landing.slideDesc1')"></div>
            <div class="sub-btn" style="width: 130px;cursor: pointer;" @click="subscribe">
                <span class="sub-btn-text">
                    {{ $t("oyu.landing.subscribe") }}
                </span>
            </div>
        </div>
        <div class="position-absolute" v-if="mobile"
            style="bottom:13%;left:10px;">
            <span class="chooseP">OYU APP Pro</span>
            <div class="pro-desc  pt-2 mb-4" v-html="$t('oyu.landing.slideDesc1')"></div>
            <div class="sub-btn" style="width: 130px;cursor: pointer;" @click="subscribe">
                <span class="sub-btn-text">
                    {{ $t("oyu.landing.subscribe") }}
                </span>
            </div>
        </div>
        <SubModal></SubModal>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import vue from 'vue';
    import SubModal from "@/components/lmslanding/SubModal"
    import { isMobile } from 'mobile-device-detect';
    export default{
        name:"Subs",
        components:{
            SubModal
        },
        data(){
            return {
            }
        },
        methods:{
            subscribe(){
                if(this.loginedUser){
                    if(Number(this.loginedUser.id)>0){
                        this.$bvModal.show('subModal');
                    }else{
                        alert(vue.i18n.translate("oyu.landing.operation"));
                    }
                }else{
                    alert(vue.i18n.translate("oyu.landing.operation"));
                }
            }
        },
        computed:{
            ...mapState([
                'loginedUser'
            ]),
            mobile:function(){
               return isMobile;
            },
            divStyle() {
                return {
                    'min-height': this.mobile ? '300px' : '394px',
               
                };
            },
        },
        created(){
            //alert(isMobile);
        }
    }
</script>
<style scoped>
    .chooseP{
        color: var(--white, #FFF);
        
        font-size: 30px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .pro-desc{
        color: var(--white, #FFF);

        /* Text lg/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .sub-btn{
        

        display: flex;
        padding: 16px 28px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: 1px solid var(--white, #FFF);
        background: var(--white, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .sub-btn-text{
        color: var(--black, #000);
        /* Text lg/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .banner-container{
        background: 
        linear-gradient(114deg, #000 0%, #000 49.48%, rgba(0, 0, 0, 0.47) 100%),
        lightgray;

    }
</style>