export default {
	logoName:'OYU',
	logOut:'Выйти',
	save :"Сохранять",
	reset:"Перезагрузить",
	oyu:{
		landing:{
			noSub:"у вас нет активной подписки.",
			activeSub:"У вас есть активная подписка.",
			passRecover:"Изменить пароль",
			changePassDesc:"Сброс пароля по электронной почте",
			sendingCode:"продолжить",
			recoverCode:"Четырехзначный код, отправленный вам на почту",
			newPass:"Новый пароль",
			newPassRe:"Повторите пароль",
			signCount:"Длина пароля должна быть больше 4 знаков",
			passChanged:"Пароль успешно изменен",
			notSame:"Пароли не совпадают",
			errorTitle:"Ошибка",
			changePassword:"изменить пароль",
			moreSign:"пароль должен содержать более 4 знаков",
			noUser:"Электронная почта не зарегистрирована",
			
			catTitle:"Категории",
			passChar:"Пароль должен содержать более более 4 знаков",
			notSame:"Пароли не совпадают",
			wrongLog:"Электронная почта или пароль неверны",
			copyright:"© 2023 OYU App. Все права защищены.",
			register:"регистрация",
			search:"поиск",
			login:"вход",
			acc:{
				title:"аккаунт",
				choosen:"таңдалған оюлар",
				choosen:"выбранные орнаменты",
				logout:"выйти с учетной записи",

				private:"персональная информация",
				sub:"ваша подписка",
				manageSub:"управление подпиской",
				download:"загрузить "
			},
			regFree:"бесплатная регистрация",
			chooseProduct: "Выбери\nсвой орнамент!",
			chooseP: "Выбрать",
			slideDesc1:"возможность получить доступ к исторической коллекции из более 700 <br>орнаментов по 13 категориям",
			subscribe : "Подписка",
			loginTitle: "Добро пожаловать в приложение OYU!",
			loginSubTitle: "выберите способ входа",

			email:"электронная почта",
			signName: "имя",
			password:"пароль",
			rePassword:"повторите пароль",
			login:"вход",
			freeReg:"бесплатная регистрация",
			signUp: "регистрация ",
			forgotPassword:"забыли пароль ?",
			registered:"Электронная почта уже зарегистрирована.",
			operation: "Вы должны войти в систему, чтобы выполнить это действие",
			firstQuestion: "Какое из следующих действий вызвало у Вас особое чувство?",
			privateOrnament: "ваш собственный орнамент",
			ornaments: "орнаменты",
			allCats: "все",
			fileType:"тип файла",
			download:"Скачать",
			backOrnaments:"вернуться к орнаментам",

			bookText:"коллекции из более 700 орнаментов",
			authorText:"“Каталога казахской орнаменты такого масштаба еще не существовало”.",
			authorName:"Ерлан Карин",
			buyText:"купить",
			buy1:"Meloman",
			buy2:"Marwin",
			downloadText1:"Сонымен қатар, қосымша бизнес үшін де, жеке қызығушылықтарға қолдану үшін де қолжетімді",
			downloadText2:"қосымшаның көмегімен арнайы сұрақтарға жауап беру арқылы өз оюыңызды таңдай аласыз.",

			aboutApp: "о сайте",
			usageTerm:"пользовательское соглашение",
			policy:"политика конфиденциальности",
			paymentProc:"процедура оплаты",

			appIntro:
				 "OYU app – веб-сайт, которое предоставляет полноценную визуальную информацию о важном элементе казахской культуры – орнаментах, позволяющее правильно организовать и развить сферу их применения. <br><br>Веб-сайт содержит более 700 видов орнаментов, сгруппированных по 13 категориям. Веб-сайт предназначено для сообщества профессиональных дизайнеров и обычных потребителей.",
		},
	},
	system:{
		errorTitle:'ошибка',
		successTitle:"Успех",
		successMsg:"Успешно завершено",
		dublicationMsg:'Повторяющаяся запись !!!',
		newRecord:'Новая запись',
		confirmMsg:'Вы уверены ?',
		selectAll:'Выбрать все',
		unSelectAll:'Снять все',
		status:'Статус',
		beginDate:"Дата начала",
		endDate:"Дата окончания",
		search:"Поиск",
		loading:"Загрузка",
		add:'добавлять',
		delete:'удалять',
		update:'Обновить',
		failMsg: 'Обнаружена неизвестная ошибка. Пожалуйста, попробуйте еще раз .',
		delete:"Удалить",
		update:"Обновлять"
	},
	comboChooseText:'--выберите--',
	mainMenu: {
		currentLang:'РУС',
		users: 'Пользователи',
		courseAndModule :"Курс && Модуль",
		lecture:"Лекция",
		lab:"Лаборатория",
		server :"Управление сервером",
		test:"Тест",
		video:"ВИДЕО"
	},
	deliveryList:{
		deliveryListTitle:"Список заказов",
		newDelivery: "Новый",
		inProgress:"В ходе выполнения",
		done:"Выполнено",
		deepSearch:"Глубокий поиск",
		cassReport:"Отчет кассы",
		byOrderNumber:"По номеру заказа",
		byNewOrder:"По новому заказу",
		
	},
	orderDate:"Дата заказа",
	orderNumber:"Номер заказа",
	userInfo:"Информация о клиенте",
	totalProductPrice:"Общая цена продукта",
	takenCostByCash:"Наличными",
	takenCostByCard:"По карте",
	deliveryLoan:"Ссуда заказа",
	orderActions:"Действия",
	showDetails:"Показать детали",
	updateOrder:"Порядок обновления",
	deleteOrder:"Удалить заказ",
	noSlicers:"Слайсеры не выбирал",
	catName:"Слайсеры не выбирал",
	measureName:"Размер",
	productCount:"Количество продуктов",
	doneCount:"Количество завершенных",
	leftCount:"Количество незавершенных",
	productPrice:'Цена продукта',
	totalPrice:"Итоговая цена",
	wareHouseName:"Склад",
	listSlicers:"Резаки",
	completed:"Завершено",
	unCompleted:"Незавершенный",
	colorName:"Цвет товара",
	teacherModule:{
		myDocuments : 'Мои документы',
		newDocument : 'Заполнение нового документа'
	},
	userPage :{
		userList:'Список пользователей'
	},
	wrongFile:"Пожалуйста, переименуйте файл. Имя загружаемого файла не может содержать точку и т.п. спецсимволы !!!",
	
	
	lms:{
		login:"Войти в систему",
		register:"Зарегистрироваться в системе",
		userList:{
			systemUsers:'Пользователи системы',
			firstName:'Имя',
			lastName:'Фамилия',
			userName:'Логин',
			phoneNumber:'Роль в системе',
			role :'Жүйедегі рөлі',
			blocking:"Блокировка",
			unblocking:"Разблокировка",
			enroll:"Зарегистрироваться"
		},
		
		userForm:{
			modalTitle:"Информация о пользователе",
			userType:"Тип пользователя",
			lastName:"Фамилия",
			firstName:"Имя",
			thirdName:"Отчества",
			phoneNumber:"Номер телефона",
			email:"Эл. адрес",
			password:"Пароль",
			repeatPassword:"Повторите пароль",
			addUser:"Добавить пользователя",
			clear:"Наглядная форма",
			roleConfirm:"Вы должны выбрать тип пользователя",
			passwordsNotMatchError:"Введенные вами пароли не совпадают!!!",
			dublicatedEmail:"Электронная почта уже зарегистрирована !!!",
			wrongLogin:"Электронная почта или пароль неверны."
		},
		courseList:{
			mainTitle:"Управление курсом и модулем",
			courseList:"Список курсов",
			courseName:"Название курса",
			moduleCount:"Модули",
			courseCount:"Курсы",
			studentCount:"Ученики",
			status:"Статус",
			delete:"Удалить",
			active:"Активный",
			inActive:"Неактивный",
			newCourse:"Новый курс [+]",
			moduleName:"Модуль",
			lectureCount:"Лекция",
			labCount:"Лабротория",
			description:"Описание",
			isFree:"Бесплатно ?",
			no:"Нет",
			yes:"Да",
			isDefault:"Главное или не главное",
			buildIntro:"Введение в курс сборки"
		},
		add:"Добавить запись",
		clear:"Наглядная форма",
		moduleForm:{
			modalTitle:"Курс создавать и обновлять",
			chooseCourses:"Выберите курсы, которые будут включать модуль"
		},
		courseForm:{
			chooseFile:"Выбрать файл ...",
			dropFile:"Перетащите файл сюда..."
		},
		moduleList:{
			moduleFiles:"Детали для модулей",
			create:"Сборка частей модуля",
			createModule:"Создать модуль"
		},
		moduleForm:{
			modalTitle:"Создавайте и обновляйте курсы",
			chooseCourses:"Выбрать курсы"
		},
		serverList:{
			mainTitle:"Серверы для строительной лаборатории",
			serverName:"Имя сервера",
			ipAddress:"Сервер IP",
			description:"Описание",
			imagePath:"Логотип",
			userName:"Имя пользователя сервера",
			password:"Пароль сервера",
			protocol:"Сервер протоколы",
			hasPublicIp:"У сервера есть внешний ip ?",
			ami:"AMI",
			ipMask:"IPMASK",
			instanceType:"Instance type"
		},
		labList:{
			mainTitle:"Лаборатории для каждого модуля",
			chooseServers:"Выберите серверы, которые будут использоваться в лаборатории",
			labName:"Название лаборатории",
			labText:"Описание лаборатории",
			labEmbedded:"Лабораторный видеоурок",
			chooseScorm:"SCORM-файл для лаборатории",
			labFiles:"Лабораторные материалы, файлы",
			createLab:"Создать лабораторию",
			servers:"Серверы",
			lectureName:"Название лекции"

		},
		videoList:{
			mainTitle:"Список видео",
			name:"Имя",
			description:"Описание",
			showVideo:"Показать видео",
			videoPath:"Код видео",
			bottomText:"Нижний текст видео",
			createVideo:"Создать видео"
		},
		catList:{
			createCat:"Создать категорию",
			catName : "Создать имя"
		},
		add:"добавлять",
		clear:"Чисто",
		chooseImage:"Выберите изображение",
		detailSuccess:"Компонент успешно создан.",
		image:"Компонент изображения",
		scorm:"Скорм компонент",
		video:"Видео компонент",
		text:"Текстовый компонент",
		detailComps:"Детали компонентов",
		courseCategory:"Категория курса [+]",
		chooseCats:"Выберите категории",
		isDefault:"Лекция по умолчанию?",
		vps:{
			manageVps:"Управление VPS",
			vpsName:"Имя VPS",
			manageVps:"Server vpses",
			chooseVpses:"Выберите VPS"
		},
		myCourses:"Мои курсы",
		mustLoginError:"Вы должны войти в систему, чтобы просмотреть курс!!!",
		selectCourses:"Выбор курсов."
	}

};