<template>
    <b-modal id="resetModal" hide-footer hide-header @hidden="clearForm">
        <div class="w-100 text-right" >
            <svg 
            style="cursor: pointer;" @click="closeModal"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="d-block text-center login-title">
            {{ $t("oyu.landing.passRecover") }}
        </div>
        <div class="d-block text-center login-sub-title">
            {{ $t("oyu.landing.changePassDesc") }}
        </div>
        <b-overlay :show="loading" rounded="sm">
            <form class="auth-form mt-5" v-on:submit.prevent="resetFormSubmit" v-if="!mailSent"> 
                
                <div class="form-group mb-2">
                <label class="d-block text-left label-text" 
                for="inputUser">{{ $t("oyu.landing.email") }}</label> 
                <input 
                    :placeholder="$t('oyu.landing.email')"
                    type="email" 
                    id="inputUser" 
                    class="form-control form-control-md label-text" 
                    required="" 
                    v-model="reset.email"
                    autofocus="">
                </div>

                <!-- <div class="form-group mb-2">
                    <label class="d-block text-left label-text" 
                    for="inputPassword">{{ $t("oyu.landing.password") }}</label>
                    <input 
                        :placeholder="$t('oyu.landing.password')"
                        type="password" 
                        id="inputPassword" 
                        class="form-control form-control-md label-text"
                        required=""
                        v-model="login.password"
                    >
                </div> -->
                <div class="form-group pt-3">
                    <button class="login-btn w-100 text-center py-3 border-0"  type="submit">
                        {{ $t("oyu.landing.sendingCode") }}
                    </button>
                </div>
            
                <div class="login-footer pt-5">
                    <span class="mr-5" @click="showRegModal">
                        {{ $t("oyu.landing.freeReg") }}
                    </span>
                    <span>
                        {{ $t("oyu.landing.forgotPassword") }}
                    </span>
                </div>
            </form>

            <form class="auth-form mt-5" v-on:submit.prevent="changePassword" v-if="mailSent"> 
                
                <div class="form-group mb-2">
                    <label class="d-block text-left label-text" for="recoverCode">
                        {{ $t("oyu.landing.recoverCode") }}
                    </label> 
                    <input 
                        :placeholder="$t('oyu.landing.recoverCode')"
                        id="recoverCode" 
                        class="form-control form-control-md label-text" 
                        required="" 
                        v-model="reset.reCode"
                        autofocus="">
                </div>

                <div class="form-group mb-2">
                    <label class="d-block text-left label-text" 
                    for="newPass">{{ $t("oyu.landing.newPass") }}</label>
                    <input 
                        :placeholder="$t('oyu.landing.newPass')"
                        type="password" 
                        id="newPass" 
                        class="form-control form-control-md label-text"
                        required=""
                        v-model="reset.password"
                    >
                </div>
                <div class="form-group mb-2">
                    <label class="d-block text-left label-text" 
                    for="newPassRe">{{ $t("oyu.landing.newPassRe") }}</label>
                    <input 
                        :placeholder="$t('oyu.landing.newPassRe')"
                        type="password" 
                        id="newPassRe" 
                        class="form-control form-control-md label-text"
                        required=""
                        v-model="reset.rePassword"
                    >
                </div>
                <div class="form-group pt-3">
                    <button class="login-btn w-100 text-center py-3 border-0"  type="submit">
                        {{ $t("oyu.landing.changePassword") }}
                    </button>
                </div>
            
                <div class="login-footer pt-5">
                    <span class="mr-5" @click="showRegModal">
                        {{ $t("oyu.landing.freeReg") }}
                    </span>
                    <span>
                        {{ $t("oyu.landing.forgotPassword") }}
                    </span>
                </div>
            </form>
        </b-overlay>
    </b-modal>
</template>
<script>
    import axios from 'axios';
    import {getHeader,loginUrl,apiDomain} from "@/config/config";
    import vue from 'vue';
    const authUser = {};
    export default {
        name:"ResetModal",
        data(){
            return {
                loading:false,
                mailSent:false,

                reset:{
                    email:"",

                    reCode:"",
                    password:"",
                    rePassword:""
                }
            }
        },
        methods:{
            clearForm(){
                this.loading=false,
                this.mailSent=false,

                this.reset={
                    email:"",

                    reCode:"",
                    password:"",
                    rePassword:""
                }
            },
            changePassword(){
                if(this.reset.password.length>3){
                    if(this.reset.password!=this.reset.rePassword){
                        alert(vue.i18n.translate("oyu.landing.notSame"));
                        return ;
                    }

                    this.loading=true;
                    axios.post(apiDomain+"/oyu/client/changepass",{
                    'password':this.reset.password,
                    'email':this.reset.email,
                    'reCode':this.reset.reCode,
                    }, {headers: getHeader()})
                    .then((res)=>{
                        this.loading=false;
                        if(res.status===200){
                            alert(vue.i18n.translate("oyu.landing.passChanged"));
                            this.closeModal();
                            this.clearForm();
                        }
                    })
                    .catch(error => {
                        this.loading=false;
                        console.log(error);
                        if(error.response.status==999){
                            alert(vue.i18n.translate("oyu.landing.wrongCode"));
                        }
                    });
                }else{
                    alert(vue.i18n.translate("oyu.landing.moreSign"));
                    return ; 
                }
                
                
            },
            resetFormSubmit(){
                this.loading=true;
                axios.post(apiDomain+"/oyu/client/sendmail",{
                'email':this.reset.email,
                }, {headers: getHeader()})
                .then((res)=>{
                    this.loading=false;
                    if(res.status===200){
                        this.mailSent=true;
                    }
                })
                .catch(error => {
                    this.loading=false;
                    console.log(error);
                    alert(vue.i18n.translate("oyu.landing.noUser"));
                });
                
            },
            showRegModal(){
                this.$bvModal.hide('loginModal');
                this.$bvModal.hide('resetModal');
                this.$bvModal.show('regModal');
            },
            closeModal(){
                this.$bvModal.hide('resetModal');
            },
            
            // handleLoginFormSubmit(){
            
            // axios.post(loginUrl,{
            //     'username':this.login.username,
            //     'password':this.login.password
            //     }, {headers: getHeader()})
            //     .then((res)=>{
            //         if(res.status===200){
            //             authUser.access_token=res.data.access_token;
            //             authUser.refresh_token = res.data.refresh_token;
            //             window.localStorage.setItem('authUser',JSON.stringify(authUser));
            //             this.$router.push({name:'AfterAuth'});
            //         }
            //         if(res.status===401){
            //             alert(vue.i18n.translate("oyu.landing.wrongLog"));
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         alert(vue.i18n.translate("oyu.landing.wrongLog"));
            //     });
            // }
        },
    }
</script>
<style scoped>
    .login-footer span{
        cursor: pointer;
        color: var(--black, #000);
        font-family: "Helvetica Neue LT W1G";
        font-size: 12px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .social-btn-container{
        border: 1px solid var(--gray-300, #D0D5DD);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        margin-right: 10px;
    }
    .login-btn{
        background: var(--black, #000);
        color: var(--white, #FFF);

        /* Text md/Regular */
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .label-text{
        color: var(--gray-700, #344054);
        font-family: "Helvetica Neue LT W1G";
        font-size: 16px;
        font-style: normal;
        font-weight: 1000;
        line-height: normal;
    }
    .login-title{
        color: var(--black, #000);
        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 18px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
    .login-sub-title{
        margin-top: 5px;
        color: var(--black, #000);

        text-align: center;
        font-family: "Helvetica Neue LT W1G";
        font-size: 15px;
        font-style: normal;
        font-weight: 100 !important;
        line-height: normal;
    }
</style>