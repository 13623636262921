import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login';
import AfterAuth from '@/components/AfterAuth';
//begining of the landing page of system
import Landing from '@/containers/Landing';
import Intro from '@/components/lmslanding/Intro';
import Privacy from '@/components/lmslanding/Privacy';
import AssignedCourse from '@/components/lmslanding/AssignedCourse';
import Course from '@/components/lmslanding/Course';
import ProductView from '@/components/lmslanding/ProductView';
import PdfView from '@/components/lmslanding/PdfView';
import Account from '@/components/lmslanding/Account';
import AccInfo from '@/components/lmslanding/AccInfo';
import Chosen from '@/components/lmslanding/Chosen';
import ProductList from '@/components/lmslanding/ProductList';


Vue.use(Router);
export default new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/afterauth',
            name: 'AfterAuth',
            component: AfterAuth,
        },
        {
            path:'/',
            redirect:'/intro',
            component: Landing,
            children: [
                {
                    path:'/intro',
                    name:'Intro',
                    component: Intro

                },
                {
                    path:'/account',
                    name:'Account',
                    component: Account,
                    redirect:"/info",
                    children:[
                        {
                            path:'/info',
                            name:"Info",
                            component:AccInfo
                        },
                        {
                            path:'/chosen',
                            name:"Chosen",
                            component:Chosen
                        }
                        
                    ]

                },
                {
                    path:'/privacy',
                    name:'Privacy',
                    component: Privacy

                },
                {
                    path:'/assigned',
                    name:'AssignedCourse',
                    component: AssignedCourse,
                    meta:{requiresAuth:true}

                },

                {
                    path:'/view/:productId',
                    name:'ProductView',
                    component: ProductView
                },
                {
                    path:'/pdfview/:path',
                    name:'PdfView',
                    component: PdfView
                },
                {
                    path:'/productlist/:catId/:catName',
                    name:'ProductList',
                    component:ProductList
                }
               
            ]
        }
    ]
})