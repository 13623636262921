<template>
     <b-row class="m-0 p-0">
        <b-col class="m-0 p-0">
          <Banner></Banner>
          <div class="d-block py-4 mb-4">
            <span class="float-left ornament-title " style="margin-left: 10px !important;">

              {{catName.length==0 ? $t("oyu.landing.ornaments") : catName}}

            </span>
            <a href="/#/productlist/0/default" class="float-right all-link mr-2">
              {{$t("oyu.landing.allCats")}}
            </a>
          </div>


          <!-- begining of the home products -->
          <b-row class="p-0 m-0"> 
            <b-col 
              
              v-for="(p,pi) in products.slice(0,16)" :key="pi"  lg="2" md="4" cols="6"
              class="pb-5" :style="[isBorderRequired(pi) ? 'border-right:1px solid #D0D5DD' : '']">
                
                  <div class="d-block text-right mb-5" @click="likeProduct(p)">
                    <img src="@/assets/love.png" width="20" height="18" v-if="p.isLiked==0">
                    <img src="@/assets/loved.png" width="20" height="18" v-if="p.isLiked>0">
                  </div>

                  <div class="d-block text-center" @click="viewProduct(p)">
                      <img style="max-width:140px;height: 80px;" :src="'https://www.oyuapp.com/api/img?imagePath='+p.image">
                  </div>
                  <div class="d-block text-center pt-3 home-p" @click="viewProduct(p)">
                      {{ 
                        $i18n.locale()=='kz' ? p.productName
                        : $i18n.locale()=='ru' ? p.productNameRu 
                        : $i18n.locale()=='en' ? p.productNameEn : "" 
                      }}
                  </div>
                </div>
            </b-col>
          </b-row>
          <!-- end of the home products -->



          <div class="d-block my-3" style="height: 1px;background-color: #D0D5DD;"></div>
          <Subs></Subs>
          <div class="d-block my-3" style="height: 1px;background-color: #D0D5DD;"></div>
          <Book></Book>
          <div class="d-block my-3" style="height: 1px;background-color: #D0D5DD;"></div>

          <!--  begining of the download section -->
          <b-row class="p-0 m-0 apps-text">
            <b-col lg="12" class="p-0 m-0 play-download">

              <div class="d-relative" style="position: relative;max-width: 550px;min-height: 350px;margin-left:auto;margin-right:auto;">
                <img src="@/assets/ios_download.png" @click="downloadApp('https://apps.apple.com/us/app/oyu-app/id6451419752')" style="width: 25%; position: absolute; bottom: 100px;left:0%;cursor: pointer;">
                <img src="@/assets/android_download.png" @click="downloadApp('https://play.google.com/store/apps/details?id=com.app.oyuapp')" style="width: 25%; position: absolute; bottom: 50px;left:0%;cursor: pointer;">
               <img src="@/assets/download_cover.png"  style="width: 70%; position: absolute; bottom: 0;right:0%;">
              </div>
                
            </b-col>
         

          </b-row>
          <!--  begining of the download section -->      
        </b-col>
     </b-row>
</template>

<script>
  import axios from 'axios';
  import {apiDomain,getHeader} from "@/config/config"; 
  import Banner from "@/components/lmslanding/Banner"
  import Subs from "@/components/lmslanding/Subs"
  import Book from "@/components/lmslanding/Book" 
  import {EventBus} from "@/EventBus.js"
  import OyuService from "@/services/oyu_service.js";
  export default {
    components:{
      Banner,
      Subs,
      Book
    },
    data() {
      return {
        sidebarOpen: false,
        products:[],
        catName:""
      }
    },
    methods: {
      downloadApp(downloadUrl){
        window.location.href=downloadUrl;
      },
      viewProduct(p){
        //window.location.href = "/#/view/"+p.productId;
        this.$router.push({ name: 'ProductView',params: { productId: 123 } });
      },
      async likeProduct(obj){
          const service = new OyuService(apiDomain,getHeader());
          try {
              const data = await service.likeProduct({"productId":obj.productId});
              if(data.isSuccess){ 
                  if(obj.isLiked>0){
                      obj.isLiked=0;
                  }else{
                      obj.isLiked=1;
                  }
              }
          } catch (error) {
              alert(error.message);
          }
      }, 
      isBorderRequired(index) {
          const itemsInRow = 6;
          return index != this.products.length - 1 && (index + 1) % itemsInRow != 0;
      },
     
      homeProducts(catId=0){
        axios.post(apiDomain+'/oyu/mobile/pagedproducts',
        {
          "pageNum":1,
          "catId":catId,
          "itemsPerPage":24
        },{headers:getHeader()})
        .then(response=>{ 
          
           this.products=response.data;
           
        })
        .catch(() => {
            this.calculating=false;
        }) 
      }
    },
    created(){
      this.homeProducts();
    },
    mounted(){
      let t = this;
      EventBus.$on("productsByCat",(d,catName)=>{
         t.catName = catName;
         t.homeProducts(Number(d));
      })
    }
};

</script>
<style scoped>
  .home-p{
    font-family: "Helvetica Neue LT W1G";
    color: var(--Black, #000);
    /* Text md/Regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }
  .apps-text{
    color: var(--oyu-system-background-light-primary, #FFF);
    font-feature-settings: 'case' on;
    font-family: "Helvetica Neue LT W1G";
    font-size: 12.364px;
    font-style: normal;
    font-weight: 1000;
    line-height: 16px; /* 129.412% */
    letter-spacing: -0.298px;
  }
  .play-download{
    background: var(--black, #000);
   
  }
  .app-download{
    background: var(--black, #000);
  }
  .ornament-title{
    color: var(--black, #000);
    /* Text lg/Semibold */
    font-family: Helvetica Neue LT W1G;
    font-size: 18px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }
  .all-link{
    color: var(--gray-600, #475467);
    text-align: right;

    /* Text sm/Regular */
    font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
  }

</style>
  


