import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    loginedUser: JSON.parse(window.localStorage.getItem('loginedUser')),
  },
  
  getters: {
    doesUserLoggedIn(state){
      alert(JSON.stringify(state.loginedUser));
    }
  },
  mutations: {
    SET_LOGINED_USER(state){
      state.loginedUser=JSON.parse(window.localStorage.getItem('loginedUser'));
    },
    LOG_OUT_SYSTEM(state){
      state.loginedUser={}
    }
  },
  actions: {
    setLoginedUser(context){
      //alert("it shoild be call the state");
      context.commit("SET_LOGINED_USER");  
    },
    logoutSystem(context){
      context.commit("LOG_OUT_SYSTEM");  
    },
    getUser(context){
      const valueFromGetter = context.getters.doesUserLoggedIn;
      return valueFromGetter;
    }
  }
});