
import App from './App.vue'
import VueHtmlToPaper from 'vue-html-to-paper';
import axios from 'axios';
import {apiDomain,getHeader} from "./config/config";
import * as VueGoogleMaps from 'vue2-google-maps'

import Vue from 'vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

Vue.use(Autocomplete)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAkUdrVbgXV_52Qrid_vdeqzTbBfmFxrNM',
    libraries: 'places',
    language: 'en',
  }
});
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
};

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = true
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
// import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)

//for the bootstrap vue
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-search-select/dist/VueSearchSelect.css' 
import {BootstrapVue,IconsPlugin} from 'bootstrap-vue'



Vue.use(IconsPlugin)
Vue.use(BootstrapVue)






// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import { VBScrollspyPlugin } from 'bootstrap-vue'
Vue.use(VBScrollspyPlugin)


import { library } from '@fortawesome/fontawesome-svg-core'
import { faWindowClose,faUserSecret,faCheck, faList, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret,faWindowClose,faCheck,faList,faPen)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'


Vue.use(VueMoment, {
    moment,
})
//Vue.config.productionTip = false

// This imports the dropdown and table plugins
import { DropdownPlugin, TablePlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
import store from './store'
import router from './router'
import vuexI18n from 'vuex-i18n';
Vue.use(vuexI18n.plugin, store, {
	moduleName: 'i18n',
	onTranslationNotFound (locale, key) {

		// return new Promise((resolve, reject) => {
    //   axios.post(apiDomain+'/login/translations'
    //   ,{locale: locale, key:key},{headers:getHeader()})
		// 	.then((result) => {

		// 		resolve(result.data);
    //   })
    //   .catch(()=>{
    //     reject();
    //   })
		// });

	}}
);
// import predefined localizations
import translationsKz from './i18n/kz.js';
import translationsRu from './i18n/ru.js';
import translationsEn from './i18n/en.js';


// add translations
Vue.i18n.add('kz', translationsKz);
Vue.i18n.add('ru', translationsRu);
Vue.i18n.add('en', translationsEn);
// default locale is kazakh
Vue.i18n.set('kz');
// synchronize the router with vuex
import {sync} from 'vuex-router-sync';
sync(store, router);

new Vue({
  
  router,
  store,
  render: h => h(App),
}).$mount('#app')
